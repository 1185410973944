import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Stack, Avatar } from '@mui/material';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import { styled, useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { useState, useContext, useEffect } from "react";
import axios from 'axios';
import { LocaleContext } from '../contexts/LocaleContext';
import '../css/nav.css';
import { Icon } from '@iconify/react';
import { ToastContext } from '../contexts/ToastContext';
import Button from '@mui/material/Button';
import useAuth from '../hooks/useAuth';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const drawerWidth = 240;
const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));


const array1 = [
    { key: 'dashboard', icon: <Icon width='22' height='22' icon="tabler:dashboard" />, path: "/" },
    { key: 'calendar', icon: <Icon width='22' height='22' icon="uit:calender" />, path: "/calendar" },
    { key: 'bookings', icon: <Icon width='22' height='22' icon="solar:bill-check-outline" />, path: "/bookings" },
    { key: 'transcations', icon: <Icon width='22' height='22' icon="ep:money" />, path: "/transcation" },

];
const array2 = [
    { key: 'rooms', icon: <Icon width='22' height='22' icon="arcticons:utd-rooms" />, path: "/rooms" },
    { key: 'roomTypes', icon: <Icon width='22' height='22' icon="ph:cards" />, path: "/roomTypes" },
    { key: 'amenities', icon: <Icon width='22' height='22' icon="guidance:hotel-room" />, path: "/amenities" },

];
const array3 = [
    { key: 'users', icon: <Icon width='22' height='22' icon="mage:users" />, path: "/users" },
    { key: 'settings', icon: <Icon width='22' height='22' icon="icons8:services" />, path: "/settings" },
];

export default function NavbarSide({ open, handleDrawerClose, setMode }) {
    const { showHideToast } = useContext(ToastContext);
    let location = useLocation();
    const [logo, setLogo] = useState('');
    const [t, i18n] = useTranslation("global");
    const theme = useTheme();
    const { locale, setLocale } = useContext(LocaleContext);
    const { setAuth } = useAuth();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const handleClose = () => {
        setLoading(false);
    };
    const handleOpen = () => {
        setLoading(true);
    };

    const handleLogout = () => {
        // Clear authentication data
        setAuth({});
        // Redirect user to login page
        navigate('/login');
    };

    function handleChangeLanguage() {
        const newLocale = locale === "en" ? "ar" : "en";
        setLocale(newLocale);
        i18n.changeLanguage(newLocale);
        localStorage.setItem("language", newLocale);
    }

    useEffect(() => {
        // Retrieve language preference from local storage
        const storedLanguage = localStorage.getItem("language");
        if (storedLanguage) {
            setLocale(storedLanguage);
            i18n.changeLanguage(storedLanguage);
        }
    }, []);

    useEffect(() => {
        handleOpen();
        axios.get('https://panorama.site-pocket.com/api/settings/logo')
            .then(response => {
                setLogo(response.data.value);
                handleClose();
            })
            .catch(function (error) {
                let errorMessage = 'An error occurred. Please try again.';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = 'Bad request. Please check your input.';
                    } else if (error.response.status === 401) {
                        errorMessage = 'Unauthorized. Please log in again.';
                    } else if (error.response.status === 403) {
                        errorMessage = 'Forbidden. You do not have permission to perform this action.';
                    } else if (error.response.status === 404) {
                        errorMessage = 'Resource not found.';
                    } else if (error.response.status === 500) {
                        errorMessage = 'Internal server error. Please try again later.';
                    } else {
                        errorMessage = 'An unexpected error occurred. Please try again later.';
                    }
                }
                handleClose();
                showHideToast(errorMessage, "error");
            });
    }, []);


    return (
        // <div className={locale === "en" ? "ltr" : "rtl"} dir={locale === "en" ? "ltr" : "rtl"}>
        <div className={locale === "en" ? "ltr" : "rtl"}>
            {loading && <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
            >
                <CircularProgress color="inherit" />
            </Backdrop>}
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >

                <DrawerHeader sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Stack direction={"row"} spacing={2} alignItems={"center"} justifyContent={"flex-start"}>
                        <img src={logo} style={{ width: "32px", height: "32px" }} alt='logo' />
                        <div>
                            <div style={{ fontWeight: "bold", fontSize: "14px", color: theme.palette.mode === "light" ? "#32324d" : theme.palette.text.primary }}>Panorama</div>
                            <div style={{ fontSize: "12px", color: theme.palette.mode === "light" ? "#666687" : theme.palette.text.secondary }}>Dashboard</div>
                        </div>

                    </Stack>
                    <IconButton onClick={handleDrawerClose} sx={{ color: theme.palette.mode === "light" ? "#666687" : theme.palette.text.secondary }}>
                        {locale === "en" ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>

                </DrawerHeader>

                <Divider />
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', }}>
                    <List sx={{ color: theme.palette.mode === "light" ? "#666687" : theme.palette.text.secondary }}>
                        {array1.map((item) => (
                            <ListItem sx={{ ".MuiTypography-root": { fontSize: "14px !important" } }} key={item.path} disablePadding>
                                <ListItemButton sx={{ bgcolor: location.pathname === item.path ? theme.palette.mode === "light" ? "#f7f7f7" : "#f7f7f714" : "transparent" }} component={Link} to={item.path}>
                                    <ListItemIcon sx={{ minWidth: "35px" }}>
                                        {item.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={t(item.key)} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>

                    <Typography sx={{ padding: "8px 16px 0", fontSize: "11px", color: theme.palette.mode === "light" ? "#666687" : theme.palette.text.secondary, fontWeight: "bold" }}>{t('Rooms')}</Typography>
                    <List sx={{ padding: "0", color: theme.palette.mode === "light" ? "#666687" : theme.palette.text.secondary, fontSize: "14px" }}>
                        {array2.map((item) => (
                            <ListItem sx={{ ".MuiTypography-root": { fontSize: "14px !important" } }} key={item.path} disablePadding>
                                <ListItemButton sx={{ bgcolor: location.pathname === item.path ? theme.palette.mode === "light" ? "#f7f7f7" : "#f7f7f714" : "transparent" }} component={Link} to={item.path}>
                                    <ListItemIcon sx={{ minWidth: "35px" }}>
                                        {item.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={t(item.key)} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>

                    <Typography sx={{ padding: "8px 16px 0", fontSize: "11px", color: theme.palette.mode === "light" ? "#666687" : theme.palette.text.secondary, fontWeight: "bold" }}>{t('Settings')}</Typography>
                    <List sx={{ padding: "0", color: theme.palette.mode === "light" ? "#666687" : theme.palette.text.secondary, fontSize: "14px" }}>
                        {array3.map((item) => (
                            <ListItem sx={{ ".MuiTypography-root": { fontSize: "14px !important" } }} key={item.path} disablePadding>
                                <ListItemButton sx={{ bgcolor: location.pathname === item.path ? theme.palette.mode === "light" ? "#f7f7f7" : "#f7f7f714" : "transparent" }} component={Link} to={item.path}>
                                    <ListItemIcon sx={{ minWidth: "35px" }}>
                                        {item.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={t(item.key)} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>

                    <Divider sx={{ marginTop: "auto", }} />

                    <Stack direction={"row"} spacing={2} alignItems={"center"} justifyContent={"flex-start"} sx={{ padding: "8px 16px 0", fontSize: "11px", color: theme.palette.mode === "light" ? "#666687" : theme.palette.text.secondary, fontWeight: "bold" }}>
                        <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" sx={{ width: 32, height: 32, bgcolor: "#4945ff" }} />
                        <Typography sx={{ fontSize: "14px", color: theme.palette.mode === "light" ? "#666687" : theme.palette.text.secondary }}>Admin</Typography>
                        <Stack>
                            {theme.palette.mode === 'dark' ? (<IconButton onClick={() => {
                                localStorage.setItem("currentMode", theme.palette.mode === "dark" ? "light" : "dark")
                                setMode((prevMode) =>
                                    prevMode === 'light' ? 'dark' : 'light',
                                );
                            }} >
                                <LightModeOutlinedIcon />
                            </IconButton>) : (<IconButton onClick={() => {
                                localStorage.setItem("currentMode", theme.palette.mode === "dark" ? "light" : "dark")
                                setMode((prevMode) =>
                                    prevMode === 'light' ? 'dark' : 'light',
                                );
                            }}>
                                <DarkModeOutlinedIcon />
                            </IconButton>)}
                        </Stack>
                        <Stack>
                            {locale === "ar" ? (<IconButton onClick={() => {
                                handleChangeLanguage()
                            }}>
                                <span id='switcheren' style={{ fontSize: "14px", color: theme.palette.mode === "light" ? "#666687" : theme.palette.text.secondary }}>EN</span>
                            </IconButton>) : (<IconButton onClick={() => handleChangeLanguage()}>
                                <span id='switcherar' style={{ fontSize: "14px", color: theme.palette.mode === "light" ? "#666687" : theme.palette.text.secondary }}>AR</span>
                            </IconButton>)}
                        </Stack>
                    </Stack>
                    <div>
                        <Button onClick={handleLogout} sx={{ color: "#ff5630", fontSize: "14px", fontWeight: "bold", textTransform: "none", padding: "0 16px", m: '0', width: "100%" }} variant="text">Log Out</Button>
                    </div>
                </Box>

            </Drawer>
        </div>
    )
}





