import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import { Box, Stack } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import axios from 'axios';
import Avatar from '@mui/material/Avatar';
import { useContext, useState, useEffect, useRef } from 'react';
import { ToastContext } from '../contexts/ToastContext';
import { useTranslation } from 'react-i18next';
import { LocaleContext } from '../contexts/LocaleContext';
import { Editor } from '@tinymce/tinymce-react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function SettingFour() {
    const { locale, setLocale } = useContext(LocaleContext);
    const { showHideToast } = useContext(ToastContext);
    const [t] = useTranslation("global");
    const [images, setImages] = useState([]);
    const [imageUrl, setImageUrl] = useState('');
    const [imagePreviews, setImagePreviews] = useState([]);
    const [originalTitle, setOriginalTitle] = useState('');
    const [originalDescription, setOriginalDescription] = useState('');
    const editorRefEn = useRef(null);
    const editorRefAr = useRef(null);

    const [loading, setLoading] = useState(false);
    const handleClose = () => {
        setLoading(false);
    };
    const handleOpen = () => {
        setLoading(true);
    };

    const [addInput, setAddInput] = useState({
        DescriptionEn: '',
        DescriptionAr: '',
    })

    useEffect(() => {
        handleOpen();
        axios.get('https://panorama.site-pocket.com/api/settings/abouttextar')
            .then(response => {
                // console.log('ar', response.data.value)
                setAddInput(prevState => ({ ...prevState, DescriptionAr: response.data.value }));
                handleClose();
            })
            .catch(function (error) {
                let errorMessage = 'An error occurred. Please try again.';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = 'Bad request. Please check your input.';
                    } else if (error.response.status === 401) {
                        errorMessage = 'Unauthorized. Please log in again.';
                    } else if (error.response.status === 403) {
                        errorMessage = 'Forbidden. You do not have permission to perform this action.';
                    } else if (error.response.status === 404) {
                        errorMessage = 'Resource not found.';
                    } else if (error.response.status === 500) {
                        errorMessage = 'Internal server error. Please try again later.';
                    } else {
                        errorMessage = 'An unexpected error occurred. Please try again later.';
                    }
                }
                handleClose();
                showHideToast(errorMessage, "error");
            });
    }, []);

    useEffect(() => {
        handleOpen();
        axios.get('https://panorama.site-pocket.com/api/settings/abouttexten')
            .then(response => {
                // console.log('en', response.data.value)
                setAddInput(prevState => ({ ...prevState, DescriptionEn: response.data.value }));
                handleClose();
            })
            .catch(function (error) {
                let errorMessage = 'An error occurred. Please try again.';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = 'Bad request. Please check your input.';
                    } else if (error.response.status === 401) {
                        errorMessage = 'Unauthorized. Please log in again.';
                    } else if (error.response.status === 403) {
                        errorMessage = 'Forbidden. You do not have permission to perform this action.';
                    } else if (error.response.status === 404) {
                        errorMessage = 'Resource not found.';
                    } else if (error.response.status === 500) {
                        errorMessage = 'Internal server error. Please try again later.';
                    } else {
                        errorMessage = 'An unexpected error occurred. Please try again later.';
                    }
                }
                handleClose();
                showHideToast(errorMessage, "error");
            });
    }, []);

    useEffect(() => {
        handleOpen();
        axios.get('https://panorama.site-pocket.com/api/settings/aboutimg')
            .then(response => {
                setImageUrl(response.data.value);
                setImagePreviews([response.data.value]);
                handleClose();
            })
            .catch(function (error) {
                let errorMessage = 'An error occurred. Please try again.';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = 'Bad request. Please check your input.';
                    } else if (error.response.status === 401) {
                        errorMessage = 'Unauthorized. Please log in again.';
                    } else if (error.response.status === 403) {
                        errorMessage = 'Forbidden. You do not have permission to perform this action.';
                    } else if (error.response.status === 404) {
                        errorMessage = 'Resource not found.';
                    } else if (error.response.status === 500) {
                        errorMessage = 'Internal server error. Please try again later.';
                    } else {
                        errorMessage = 'An unexpected error occurred. Please try again later.';
                    }
                }
                handleClose();
                showHideToast(errorMessage, "error");
            });
    }, []);

    function handleEditorChangeEn(content) {
        setAddInput({ ...addInput, DescriptionEn: content });
    }

    function handleEditorChangeAr(content) {
        setAddInput({ ...addInput, DescriptionAr: content });
    }

    const handleImageUpload = (e) => {
        const files = Array.from(e.target.files);

        // Clear existing image previews
        setImagePreviews([]);

        // Generate image preview for the uploaded image
        files.forEach((file) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                setImagePreviews((prevPreviews) => [...prevPreviews, reader.result]);
            };
        });

        setImages(files);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        handleOpen();
        const textarApi = `https://panorama.site-pocket.com/api/settings/abouttextar`;
        const textenApi = `https://panorama.site-pocket.com/api/settings/abouttexten`;
        const aboutimgApi = `https://panorama.site-pocket.com/api/settings/aboutimg`;

        const tokenString = localStorage.getItem('auth');
        const tokenObject = JSON.parse(tokenString);
        const token = tokenObject.accessToken;

        const requests = [];

        // Check if title has changed
        if (addInput.DescriptionAr !== originalTitle) { // originalTitle is the initial title fetched from the API
            const descriptionArFormData = new FormData();
            descriptionArFormData.append('_method', 'PUT');
            descriptionArFormData.append('value', addInput.DescriptionAr);

            requests.push(
                axios.post(textarApi, descriptionArFormData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}`,
                    }
                })
            );
        }

        // Check if description has changed
        if (addInput.DescriptionEn !== originalDescription) { // originalDescription is the initial description fetched from the API
            const descriptionEnFormData = new FormData();
            descriptionEnFormData.append('_method', 'PUT');
            descriptionEnFormData.append('value', addInput.DescriptionEn);

            requests.push(
                axios.post(textenApi, descriptionEnFormData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}`,
                    }
                })
            );
        }

        // Check if image has changed
        if (images.length > 0) {
            const imageFormData = new FormData();
            imageFormData.append('_method', 'PUT');
            imageFormData.append('value', images[0]);

            requests.push(
                axios.post(aboutimgApi, imageFormData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}`,
                    }
                })
            );
        }

        Promise.all(requests)
            .then(function (responses) {
                responses.forEach(response => {
                    if (response.config.url === textarApi) {
                        setAddInput(prevState => ({ ...prevState, DescriptionAr: response.data.value }));
                    } else if (response.config.url === textenApi) {
                        setAddInput(prevState => ({ ...prevState, DescriptionEn: response.data.value }));
                    } else if (response.config.url === aboutimgApi) {
                        setImageUrl(response.data.value);
                        setImagePreviews([response.data.value]);
                    }
                });
                // console.log(responses);
                handleClose();
                showHideToast(t("edited successfully"));
            })
            .catch(function (error) {
                let errorMessage = 'An error occurred. Please try again.';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = 'Bad request. Please check your input.';
                    } else if (error.response.status === 401) {
                        errorMessage = 'Unauthorized. Please log in again.';
                    } else if (error.response.status === 403) {
                        errorMessage = 'Forbidden. You do not have permission to perform this action.';
                    } else if (error.response.status === 404) {
                        errorMessage = 'Resource not found.';
                    } else if (error.response.status === 500) {
                        errorMessage = 'Internal server error. Please try again later.';
                    } else {
                        errorMessage = 'An unexpected error occurred. Please try again later.';
                    }
                }
                handleClose();
                showHideToast(errorMessage, "error");
            });
    };


    return (
        <div style={{ marginBottom: '20px' }}>
            {loading && <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
            >
                <CircularProgress color="inherit" />
            </Backdrop>}
            <Paper elevation={0} sx={{ display: 'flex', justifyContent: 'space-between', padding: '24px', borderRadius: '16px', boxShadow: 'rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px' }}>
                <Grid container spacing={2} sx={{ width: '100%' }}>
                    <Grid item xs={12} md={6} sx={{ width: '100%' }}>
                        <Box direction="column" spacing={2} sx={{ width: '100%' }}>
                            <div style={{ fontSize: '16px', fontWeight: '600' }}>{t('Slider 5')}</div>
                            <div className="editor" style={{ display: 'flex', flexDirection: 'column', gap: '12px', marginTop: '20px' }}>
                                <h6 style={{ fontSize: '14px', margin: '0' }}>Description En</h6>
                                <Editor
                                    apiKey='5ny3az9fqgoxdixg9ym8t2egthg0r8k58vbq8d7uv5kgkldw'
                                    onInit={(evt, editor) => editorRefEn.current = editor}
                                    initialValue={addInput.DescriptionEn}
                                    init={{
                                        height: 300,
                                        resize: false,
                                        // menubar: false,
                                        plugins: [
                                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                        ],
                                        toolbar: 'undo redo | blocks | ' +
                                            'bold italic forecolor | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                            'removeformat | help',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }}
                                    onChange={(event, editor) => handleEditorChangeEn(editor.getContent())}
                                />
                            </div>

                            <div className="editor" style={{ display: 'flex', flexDirection: 'column', gap: '12px', marginTop: '20px' }}>
                                <h6 style={{ fontSize: '14px', margin: '0' }}>Description Ar</h6>
                                <Editor
                                    apiKey='5ny3az9fqgoxdixg9ym8t2egthg0r8k58vbq8d7uv5kgkldw'
                                    onInit={(evt, editor) => editorRefAr.current = editor}
                                    initialValue={addInput.DescriptionAr}
                                    init={{
                                        height: 300,
                                        resize: false,
                                        // menubar: false,
                                        plugins: [
                                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                        ],
                                        toolbar: 'undo redo | blocks | ' +
                                            'bold italic forecolor | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                            'removeformat | help',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }}
                                    onChange={(event, editor) => handleEditorChangeAr(editor.getContent())}
                                />
                            </div>

                            <Button onClick={handleSubmit} sx={{ mt: '20px', textTransform: 'capitalize' }} variant="contained">{t('Submit')}</Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ width: '100%' }}>
                        <input type="file" accept="image/*" style={{ display: 'none' }} onChange={handleImageUpload} />
                        <Button variant="outlined" component="label" sx={{ marginTop: 2, textTransform: 'capitalize' }}>
                            {t('Choose Image')}
                            <input type="file" accept="image/*" style={{ display: 'none' }} onChange={handleImageUpload} />
                        </Button>
                        <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '10px' }}>
                            {imagePreviews.map((preview, index) => (
                                <div key={index} style={{ position: 'relative', marginRight: '10px', marginBottom: '10px', width: '80px', height: '80px' }}>
                                    <Avatar variant="square" sx={{ width: '80px', height: '80px' }} alt={`Preview ${index}`} src={preview} />
                                </div>
                            ))}
                        </div>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}
