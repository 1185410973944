import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTheme } from '@mui/material/styles';
import '../css/apexchart.css';

const ApexChart = () => {
  const theme = useTheme();
  const [chartData, setChartData] = useState({
    series: [44, 55, 41, 17, 15],
    options: {
      chart: {
        type: 'donut',
      },
      plotOptions: {
        pie: {
          colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0']
        }
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }
      ]
    }
  });

  return (
    <div className={theme.palette.mode === 'light' ? 'light' : 'dark'} style={{marginTop: '30px'}}>
      <div id="chart">
        <ReactApexChart options={chartData.options} series={chartData.series} type="donut" height={300} />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default ApexChart;
