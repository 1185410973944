import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';

import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastContext } from '../../contexts/ToastContext';
import { LocaleContext } from '../../contexts/LocaleContext';
import { useTheme } from '@mui/material/styles';

export default function UserTableAddCrud({ showAddDialog, setShowAddDialog, setRoomtype, setRunUseEffect }) {
    const { locale, setLocale } = useContext(LocaleContext);
    const { showHideToast } = useContext(ToastContext);
    const [t] = useTranslation("global");
    const theme = useTheme({
        Typography: {
            fontFamily: [
                'Almarai',
            ].join(','),
        }
    });

    const [loading, setLoading] = useState(false);
    const handleClose = () => {
        setLoading(false);
    };
    const handleOpen = () => {
        setLoading(true);
    };

    //  ====== open && close add state ========
    const [addInput, setAddInput] = useState({
        Name: '',
        Email: '',
        Phone: '',
        Password: '',
        Nationality: '',
        Verified: false,
        Admin: false,
    })
    //  ====== open && close add state ========

    //  ================ add function ================
    function handleAddClose() {
        setShowAddDialog(false);
    }

    function handleDialogSubmit(event) {
        event.preventDefault();
        handleOpen();
        const url = 'https://panorama.site-pocket.com/api/users';
        const params = {
            name: addInput.Name,
            email: addInput.Email,
            phone: addInput.Phone,
            password: addInput.Password,
            nationality: addInput.Nationality,
            email_verified: addInput.Verified,
            is_admin: addInput.Admin,
        };
        const tokenString = localStorage.getItem('auth');
        const tokenObject = JSON.parse(tokenString);
        const token = tokenObject.accessToken;

        axios.post(url, params, {
            headers: { 'Authorization': 'Bearer ' + token },
        })
            .then(function (response) {
                const newUser = response.data.user;
                const newRow = {
                    id: newUser.id,
                    name: newUser.name,
                    email: newUser.email,
                    phone: newUser.phone,
                    password: newUser.password,
                    nationality: newUser.nationality,
                    email_verified: newUser.email_verified_at,
                    is_admin: newUser.is_admin,
                };
                setRoomtype(prevRows => [...prevRows, newRow]); // Update roomtype state with new data
                showHideToast(t("added successfully"));
                setShowAddDialog(false);
                setAddInput({
                    Name: "",
                    Email: "",
                    Phone: "",
                    Password: "",
                    Nationality: "",
                    Verified: false,
                    Admin: false,
                });
                setRunUseEffect((prev) => prev + 1);
                handleClose();
            })
            .catch(function (error) {
                let errorMessage = 'An error occurred. Please try again.';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = 'Bad request. Please check your input.';
                    } else if (error.response.status === 401) {
                        errorMessage = 'Unauthorized. Please log in again.';
                    } else if (error.response.status === 403) {
                        errorMessage = 'Forbidden. You do not have permission to perform this action.';
                    } else if (error.response.status === 404) {
                        errorMessage = 'Resource not found.';
                    } else if (error.response.status === 500) {
                        errorMessage = 'Internal server error. Please try again later.';
                    } else if (error.response.data.message.includes('The email field must be a valid email')) {
                        errorMessage = 'you need to enter valid email.';
                    } else if (error.response.data.message.includes('The password field must be at least 6 characters')) {
                        errorMessage = 'The password field must be at least 6 characters.';
                    } else {
                        errorMessage = 'An unexpected error occurred. Please try again later.';
                    }
                }
                handleClose();
                showHideToast(errorMessage, "error");
            });
    }
    //  ================ add function ================
    return (
        <div>
            {loading && <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
            >
                <CircularProgress color="inherit" />
            </Backdrop>}
            <Dialog
                className={locale === "en" ? "ltr" : "rtl"}
                dir={locale === "en" ? "ltr" : "rtl"}
                open={showAddDialog}
                onClose={handleAddClose}
                sx={{ width: "500px", margin: "auto" }}
            >
                <DialogTitle sx={{ pb: 0 }}>{t('Add')}</DialogTitle>
                <DialogContent sx={{ pt: '10px !important' }}>
                    <TextField
                        size="small"
                        required
                        type='text'
                        sx={{ mb: 2, }}
                        fullWidth
                        label={t("Name")}
                        name="Name"
                        value={addInput.Name}
                        onChange={(e) => {
                            setAddInput({ ...addInput, Name: e.target.value })
                        }}
                    />
                    <TextField
                        size="small"
                        required
                        sx={{ mb: 2 }}
                        type='text'
                        fullWidth
                        name='Email'
                        label={t("Email")}
                        value={addInput.Email}
                        onChange={(e) => {
                            setAddInput({ ...addInput, Email: e.target.value })
                        }}
                    />
                    <TextField
                        size="small"
                        required
                        sx={{ mb: 2 }}
                        type='text'
                        fullWidth
                        name='Phone'
                        label={t("Phone")}
                        value={addInput.Phone}
                        onChange={(e) => {
                            setAddInput({ ...addInput, Phone: e.target.value })
                        }}
                    />
                    <TextField
                        size="small"
                        required
                        sx={{ mb: 2 }}
                        type='password'
                        fullWidth
                        name='Password'
                        label={t("Password")}
                        value={addInput.Password}
                        onChange={(e) => {
                            setAddInput({ ...addInput, Password: e.target.value })
                        }}
                    />
                    <TextField
                        size="small"
                        sx={{ mb: 2 }}
                        type='text'
                        fullWidth
                        name='Nationality'
                        label={t("Nationality Id")}
                        value={addInput.Nationality}
                        onChange={(e) => {
                            setAddInput({ ...addInput, Nationality: e.target.value })
                        }}
                    />
                    <span>{t('Email Verified')}</span>
                    <Switch
                        checked={addInput.Verified}
                        onChange={(e) => {
                            setAddInput({ ...addInput, Verified: e.target.checked })
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <span>{t('Is Admin')}</span>
                    <Switch
                        checked={addInput.Admin}
                        onChange={(e) => setAddInput({ ...addInput, Admin: e.target.checked })}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />

                </DialogContent>

                <DialogActions>
                    <Button sx={{ textTransform: 'capitalize' }} variant="contained" color="error" onClick={handleAddClose}>{t("Cancel")}</Button>
                    <Button
                        sx={{ textTransform: 'capitalize' }}
                        variant="contained"
                        disabled={!addInput.Name || !addInput.Email || !addInput.Phone || !addInput.Password}
                        onClick={handleDialogSubmit} type="submit">{t("Submit")}</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
