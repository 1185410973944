import { Container } from '@mui/material';
import UsersTable from '../component/UsersTable';

export default function Users() {
    return (
        <div>
            <Container maxWidth="xl">
                <UsersTable />
            </Container>
        </div>
    )
}
