import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import { Box, Stack } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import axios from 'axios';
import Avatar from '@mui/material/Avatar';
import { useContext, useState, useEffect } from 'react';
import { ToastContext } from '../contexts/ToastContext';
import { useTranslation } from 'react-i18next';
import { LocaleContext } from '../contexts/LocaleContext';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function SettingSecond() {
    const { locale, setLocale } = useContext(LocaleContext);
    const { showHideToast } = useContext(ToastContext);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [images, setImages] = useState([]);
    const [imageUrl, setImageUrl] = useState('');
    const [imagePreviews, setImagePreviews] = useState([]);
    const [originalTitle, setOriginalTitle] = useState('');
    const [originalDescription, setOriginalDescription] = useState('');

    const [loading, setLoading] = useState(false);
    const handleClose = () => {
        setLoading(false);
    };
    const handleOpen = () => {
        setLoading(true);
    };

    useEffect(() => {
        handleOpen();
        axios.get('https://panorama.site-pocket.com/api/settings/slider2title')
            .then(response => {
                setTitle(response.data.value);
                handleClose();
            })
            .catch(function (error) {
                let errorMessage = 'An error occurred. Please try again.';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = 'Bad request. Please check your input.';
                    } else if (error.response.status === 401) {
                        errorMessage = 'Unauthorized. Please log in again.';
                    } else if (error.response.status === 403) {
                        errorMessage = 'Forbidden. You do not have permission to perform this action.';
                    } else if (error.response.status === 404) {
                        errorMessage = 'Resource not found.';
                    } else if (error.response.status === 500) {
                        errorMessage = 'Internal server error. Please try again later.';
                    } else {
                        errorMessage = 'An unexpected error occurred. Please try again later.';
                    }
                }
                handleClose();
                showHideToast(errorMessage, "error");
            });
    }, []);

    useEffect(() => {
        handleOpen();
        axios.get('https://panorama.site-pocket.com/api/settings/slider2desc')
            .then(response => {
                setDescription(response.data.value);
                handleClose();
            })
            .catch(function (error) {
                let errorMessage = 'An error occurred. Please try again.';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = 'Bad request. Please check your input.';
                    } else if (error.response.status === 401) {
                        errorMessage = 'Unauthorized. Please log in again.';
                    } else if (error.response.status === 403) {
                        errorMessage = 'Forbidden. You do not have permission to perform this action.';
                    } else if (error.response.status === 404) {
                        errorMessage = 'Resource not found.';
                    } else if (error.response.status === 500) {
                        errorMessage = 'Internal server error. Please try again later.';
                    } else {
                        errorMessage = 'An unexpected error occurred. Please try again later.';
                    }
                }
                handleClose();
                showHideToast(errorMessage, "error");
            });
    }, []);

    useEffect(() => {
        handleOpen();
        axios.get('https://panorama.site-pocket.com/api/settings/slider2img')
            .then(response => {
                setImageUrl(response.data.value);
                setImagePreviews([response.data.value]);
                handleClose();
            })
            .catch(function (error) {
                let errorMessage = 'An error occurred. Please try again.';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = 'Bad request. Please check your input.';
                    } else if (error.response.status === 401) {
                        errorMessage = 'Unauthorized. Please log in again.';
                    } else if (error.response.status === 403) {
                        errorMessage = 'Forbidden. You do not have permission to perform this action.';
                    } else if (error.response.status === 404) {
                        errorMessage = 'Resource not found.';
                    } else if (error.response.status === 500) {
                        errorMessage = 'Internal server error. Please try again later.';
                    } else {
                        errorMessage = 'An unexpected error occurred. Please try again later.';
                    }
                }
                handleClose();
                showHideToast(errorMessage, "error");
            });
    }, []);


    const handleImageUpload = (e) => {
        const files = Array.from(e.target.files);
        // Clear existing image previews
        setImagePreviews([]);
        // Generate image preview for the uploaded image
        files.forEach((file) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                setImagePreviews((prevPreviews) => [...prevPreviews, reader.result]);
            };
        });

        setImages(files);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        handleOpen();
        const titleApi = `https://panorama.site-pocket.com/api/settings/slider2title`;
        const descriptionApi = `https://panorama.site-pocket.com/api/settings/slider2desc`;
        const imageApi = `https://panorama.site-pocket.com/api/settings/slider2img`;

        const tokenString = localStorage.getItem('auth');
        const tokenObject = JSON.parse(tokenString);
        const token = tokenObject.accessToken;

        const requests = [];

        // Check if title has changed
        if (title !== originalTitle) { // originalTitle is the initial title fetched from the API
            const titleFormData = new FormData();
            titleFormData.append('_method', 'PUT');
            titleFormData.append('value', title);

            requests.push(
                axios.post(titleApi, titleFormData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}`,
                    }
                })
            );
        }

        // Check if description has changed
        if (description !== originalDescription) { // originalDescription is the initial description fetched from the API
            const descriptionFormData = new FormData();
            descriptionFormData.append('_method', 'PUT');
            descriptionFormData.append('value', description);

            requests.push(
                axios.post(descriptionApi, descriptionFormData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}`,
                    }
                })
            );
        }

        // Check if image has changed
        if (images.length > 0) {
            const imageFormData = new FormData();
            imageFormData.append('_method', 'PUT');
            imageFormData.append('value', images[0]);

            requests.push(
                axios.post(imageApi, imageFormData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}`,
                    }
                })
            );
        }

        Promise.all(requests)
            .then(function (responses) {
                responses.forEach(response => {
                    if (response.config.url === titleApi) {
                        setTitle(response.data.value);
                    } else if (response.config.url === descriptionApi) {
                        setDescription(response.data.value);
                    } else if (response.config.url === imageApi) {
                        setImageUrl(response.data.value);
                        setImagePreviews([response.data.value]);
                    }
                });
                handleClose();
                showHideToast(t("edited successfully"));
            })
            .catch(function (error) {
                let errorMessage = 'An error occurred. Please try again.';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = 'Bad request. Please check your input.';
                    } else if (error.response.status === 401) {
                        errorMessage = 'Unauthorized. Please log in again.';
                    } else if (error.response.status === 403) {
                        errorMessage = 'Forbidden. You do not have permission to perform this action.';
                    } else if (error.response.status === 404) {
                        errorMessage = 'Resource not found.';
                    } else if (error.response.status === 500) {
                        errorMessage = 'Internal server error. Please try again later.';
                    } else {
                        errorMessage = 'An unexpected error occurred. Please try again later.';
                    }
                }
                handleClose();
                showHideToast(errorMessage, "error");
            });
    };

    const [t] = useTranslation("global");
    return (
        <div style={{ marginBottom: '20px' }}>
            {loading && <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
            >
                <CircularProgress color="inherit" />
            </Backdrop>}
            <Paper elevation={0} sx={{ display: 'flex', justifyContent: 'space-between', padding: '24px', borderRadius: '16px', boxShadow: 'rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px' }}>
                <Grid container spacing={2} sx={{ width: '100%' }}>
                    <Grid item xs={12} md={6} sx={{ width: '100%' }}>
                        <Box direction="column" spacing={2} sx={{ width: '100%' }}>
                            <div style={{ fontSize: '16px', fontWeight: '600' }}>{t('Slider 2')}</div>
                            <TextField value={title} onChange={(e) => setTitle(e.target.value)} size="small" id="outlined-basic" label={t("Title")} variant="outlined" sx={{ width: '100%', mt: '10px', mb: '1 !important' }} />
                            <TextField value={description} onChange={(e) => setDescription(e.target.value)} size="small" id="outlined-basic" label={t("Description")} variant="outlined" sx={{ width: '100%', mt: '10px !important' }} />
                            <Button onClick={handleSubmit} sx={{ mt: '20px', textTransform: 'capitalize' }} variant="contained">{t('Submit')}</Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ width: '100%' }}>
                        <input type="file" accept="image/*" style={{ display: 'none' }} onChange={handleImageUpload} />
                        <Button variant="outlined" component="label" sx={{ marginTop: 2, textTransform: 'capitalize' }}>
                            {t('Choose Image')}
                            <input type="file" accept="image/*" style={{ display: 'none' }} onChange={handleImageUpload} />
                        </Button>
                        <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '10px' }}>
                            {imagePreviews.map((preview, index) => (
                                <div key={index} style={{ position: 'relative', marginRight: '10px', marginBottom: '10px', width: '80px', height: '80px' }}>
                                    <Avatar variant="square" sx={{ width: '80px', height: '80px' }} alt={`Preview ${index}`} src={preview} />
                                </div>
                            ))}
                        </div>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
}
