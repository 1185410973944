import { Box, Button, Stack } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import AddIcon from '@mui/icons-material/Add';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { red, green, orange } from '@mui/material/colors';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import axios from 'axios';
import "../css/roomstables.css"
import { useMemo, useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastContext } from '../contexts/ToastContext';
import { LocaleContext } from '../contexts/LocaleContext';
import { useTheme } from '@mui/material/styles';
import RoomsTableAddCrud from './CRUD/RoomsTableAddCrud';
import RoomsTableEditCrud from './CRUD/RoomsTableEditCrud';

export default function RoomsTable() {
    const [selectedImages, setSelectedImages] = useState([]);
    const [imagePreviews, setImagePreviews] = useState([]);
    const [roomtype, setRoomtype] = useState([]);
    const [roomTypes, setRoomTypes] = useState([]);
    const [amenities, setAmenities] = useState([]);
    const [selectedAmenities, setSelectedAmenities] = useState([]);
    const { locale, setLocale } = useContext(LocaleContext);
    const { showHideToast } = useContext(ToastContext);
    const [runUseEffect, setRunUseEffect] = useState(0);

    const [t] = useTranslation("global");
    const theme = useTheme({
        Typography: {
            fontFamily: [
                'Almarai',
            ].join(','),
        }
    });

    const [loading, setLoading] = useState(false);
    const handleClose = () => {
        setLoading(false);
    };
    const handleOpen = () => {
        setLoading(true);
    };

    // get all data
    useEffect(() => {
        handleOpen();
        axios.get('https://panorama.site-pocket.com/api/room-types')
            .then(response => {
                setRoomTypes(response.data.roomTypes);
                handleClose();
            })
            .catch(function (error) {
                let errorMessage = 'An error occurred. Please try again.';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = 'Bad request. Please check your input.';
                    } else if (error.response.status === 401) {
                        errorMessage = 'Unauthorized. Please log in again.';
                    } else if (error.response.status === 403) {
                        errorMessage = 'Forbidden. You do not have permission to perform this action.';
                    } else if (error.response.status === 404) {
                        errorMessage = 'Resource not found.';
                    } else if (error.response.status === 500) {
                        errorMessage = 'Internal server error. Please try again later.';
                    } else {
                        errorMessage = 'An unexpected error occurred. Please try again later.';
                    }
                }
                handleClose();
                showHideToast(errorMessage, "error");
            });
    }, []);

    useEffect(() => {
        handleOpen();
        axios.get('https://panorama.site-pocket.com/api/amenities')
            .then(response => {
                setAmenities(response.data.amenities);
                handleClose();
            })
            .catch(function (error) {
                let errorMessage = 'An error occurred. Please try again.';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = 'Bad request. Please check your input.';
                    } else if (error.response.status === 401) {
                        errorMessage = 'Unauthorized. Please log in again.';
                    } else if (error.response.status === 403) {
                        errorMessage = 'Forbidden. You do not have permission to perform this action.';
                    } else if (error.response.status === 404) {
                        errorMessage = 'Resource not found.';
                    } else if (error.response.status === 500) {
                        errorMessage = 'Internal server error. Please try again later.';
                    } else {
                        errorMessage = 'An unexpected error occurred. Please try again later.';
                    }
                }
                handleClose();
                showHideToast(errorMessage, "error");
            });
    }, []);

    useEffect(() => {
        handleOpen();
        axios.get('https://panorama.site-pocket.com/api/rooms',)
            .then(function (response) {
                setRoomtype(response.data.rooms);
                handleClose();
            })
            .catch(function (error) {
                let errorMessage = 'An error occurred. Please try again.';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = 'Bad request. Please check your input.';
                    } else if (error.response.status === 401) {
                        errorMessage = 'Unauthorized. Please log in again.';
                    } else if (error.response.status === 403) {
                        errorMessage = 'Forbidden. You do not have permission to perform this action.';
                    } else if (error.response.status === 404) {
                        errorMessage = 'Resource not found.';
                    } else if (error.response.status === 500) {
                        errorMessage = 'Internal server error. Please try again later.';
                    } else {
                        errorMessage = 'An unexpected error occurred. Please try again later.';
                    }
                }
                handleClose();
                showHideToast(errorMessage, "error");
            });
    }, [runUseEffect]);

    //  ====== open && close add state ========
    const [showAddDialog, setShowAddDialog] = useState(false);

    const handleCheckboxChange = (event, amenityId) => {
        if (event.target.checked) {
            setSelectedAmenities(prevSelectedAmenities => [...prevSelectedAmenities, amenityId]);
        } else {
            setSelectedAmenities(prevSelectedAmenities => prevSelectedAmenities.filter(id => id !== amenityId));
        }
    };
    //  ====== open && close add state ========

    //  ====== open && close edit state ========
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [rowToEdit, setRowToEdit] = useState(null);
    const [updatedRows, setUpdatedRows] = useState({
        RoomNo: '',
        Price: '',
        Status: '',
        RoomTypeId: '',
    });
    //  ====== open && close edit state ========

    //  ====== open && close delete state ========
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [rowToDelete, setRowToDelete] = useState(null);
    //  ====== open && close delete state ========

    function handleEditImageChange(event) {
        const files = event.target.files;

        // Filter out non-File objects
        const validFiles = Array.from(files).filter(file => file instanceof File);

        const newSelectedImages = [...selectedImages, ...validFiles];
        setSelectedImages(newSelectedImages);

        // Preview images
        const previews = validFiles.map(file => URL.createObjectURL(file));

        // Concatenate new previews with existing previews
        setImagePreviews([...imagePreviews, ...previews]);
    }

    // Remove image from selected images
    const handleEditRemoveImage = (index) => {
        // Filter out the image at the specified index from both arrays
        const updatedImages = selectedImages.filter((_, i) => i !== index);
        const updatedPreviews = imagePreviews.filter((_, i) => i !== index);

        // Update the state with the filtered arrays
        setSelectedImages(updatedImages);
        setImagePreviews(updatedPreviews);
    };


    //  ================ add function ================
    function handleAddClick() {
        setShowAddDialog(true);
        const imagePaths = [];
        setImagePreviews(imagePaths);
    }
    //  ================ add function ================

    //  ================ edit function ================
    function handleEditClick(id) {
        const row = roomtype.find((row) => row.id === id);
        const amenityIds = row.amenities.map(amenity => amenity.pivot.amenity_id);
        setSelectedAmenities(amenityIds);
        setUpdatedRows({
            Price: row.price,
            RoomNo: row.room_number,
            Status: row.status,
            RoomTypeId: row.room_type_id,
        });
        const imagePaths = row.image_path ? row.image_path.split(',') : [];
        setImagePreviews(imagePaths);

        // Populate selectedImages with File objects
        const fileObjects = imagePaths.map(path => ({ path, isNew: false }));
        setSelectedImages(fileObjects);

        setRowToEdit(id);
        setShowEditDialog(true);
    }
    //  ================ edit function ================

    //  ================ delete function ================
    function handleDeleteClick(id) {
        setRowToDelete(id);
        setShowDeleteDialog(true);
    }

    function handleDeleteClose() {
        setShowDeleteDialog(false);
    }

    function handleDeleteConfirm() {
        handleOpen();
        const url = `https://panorama.site-pocket.com/api/rooms/${rowToDelete}`;

        const tokenString = localStorage.getItem('auth');
        const tokenObject = JSON.parse(tokenString);
        const token = tokenObject.accessToken;

        axios.delete(url, {
            headers: { Authorization: `Bearer ${token}` },
        })
            .then(function (response) {
                const updatedRows = roomtype.filter((row) => row.id !== rowToDelete);
                setRoomtype(updatedRows);
                setShowDeleteDialog(false);
                showHideToast(t("deleted successfully"));
                handleClose();
            })
            .catch(function (error) {
                let errorMessage = 'An error occurred. Please try again.';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = 'Bad request. Please check your input.';
                    } else if (error.response.status === 401) {
                        errorMessage = 'Unauthorized. Please log in again.';
                    } else if (error.response.status === 403) {
                        errorMessage = 'Forbidden. You do not have permission to perform this action.';
                    } else if (error.response.status === 404) {
                        errorMessage = 'Resource not found.';
                    } else if (error.response.status === 500) {
                        errorMessage = 'Internal server error. Please try again later.';
                    } else {
                        errorMessage = 'An unexpected error occurred. Please try again later.';
                    }
                }
                handleClose();
                showHideToast(errorMessage, "error");
            });
    }

    const columns = useMemo(
        () => [
            {
                accessorKey: 'index',
                header: '#',
                size: 80,
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                },
                muiTableFooterCellProps: {
                    align: 'center',
                },
            },
            {
                accessorKey: 'image_path',
                header: (t('Image')),
                size: 150,
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                },
                muiTableFooterCellProps: {
                    align: 'center',
                },
                Cell: ({ cell }) => (
                    <img src={JSON.stringify(cell.row.original.image_path).split(',')[0].replace(/["\[\]]/g, ' ')} alt='' style={{ width: '80px', maxHeight: '100px' }} />
                ),
            },
            {
                accessorKey: 'amenities',
                header: (t('Amenities')),
                size: 150,
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                },
                muiTableFooterCellProps: {
                    align: 'center',
                },
                Cell: ({ cell }) => (
                    <div className='amenitiesCell'>
                        {cell.row.original.amenities && cell.row.original.amenities.length > 0 ? (
                            cell.row.original.amenities.map((amenity) => (
                                <span key={amenity.pivot.amenity_id}>
                                    <img src={amenity.image_path} alt='' style={{ width: '20px', maxHeight: '100px', marginLeft: '5px' }} />
                                </span>
                            ))
                        ) : (
                            <span></span>
                        )}
                    </div>
                ),
            },
            {
                accessorKey: 'room_number',
                header: (t('Room_No.')),
                size: 150,
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                },
                muiTableFooterCellProps: {
                    align: 'center',
                },
            },
            {
                accessorKey: 'price',
                header: (t('Price')),
                size: 80,
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                },
                muiTableFooterCellProps: {
                    align: 'center',
                },
            },
            {
                accessorKey: 'status',
                header: (t('Status')),
                size: 150,
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                },
                muiTableFooterCellProps: {
                    align: 'center',
                },
                Cell: ({ cell }) => (
                    <Box
                        component="div"
                        sx={{
                            backgroundColor:
                                cell.getValue() === 'available'
                                    ? green[100]
                                    : red[100],
                            border: `1px solid ${cell.getValue() === 'available'
                                ? green[300]
                                : red[300]}`,
                            borderRadius: '4px',
                            color:
                                cell.getValue() === 'available'
                                    ? green[700]
                                    : red[700],
                        }}
                    >
                        {cell.getValue()}
                    </Box>
                ),
            },
            {
                accessorKey: 'room_type',
                header: (t('Type')),
                size: 80,
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                },
                muiTableFooterCellProps: {
                    align: 'center',
                },
                Cell: ({ cell }) => (
                    <div>{cell.row.original.room_type ? locale === 'en' ? cell.row.original.room_type.name_en : cell.row.original.room_type.name_ar : ''}</div>
                ),
            },
            {
                accessorKey: 'Action',
                header: (t('Actions')),
                size: 150,
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                },
                muiTableFooterCellProps: {
                    align: 'center',
                },
                Cell: ({ cell }) => (
                    <Stack direction="row" spacing={0.5} sx={{ justifyContent: 'center' }}>
                        <IconButton onClick={() => handleEditClick(cell.row.original.id)}>
                            <ModeEditIcon color="primary" fontSize="small" />
                        </IconButton>
                        <IconButton onClick={() => handleDeleteClick(cell.row.original.id)}>
                            <DeleteIcon color="error" fontSize="small" />
                        </IconButton>
                    </Stack>
                ),
            },
        ],
        [t, setRoomtype, roomtype, handleEditClick, handleDeleteClick, roomTypes, setRoomTypes],
    );

    const renderedRows = useMemo(() => {
        return roomtype.map((row, index) => {
            return { ...row, index: index + 1 };
        });
    }, [roomtype, roomTypes, setRoomTypes]);

    const table = useMaterialReactTable({
        columns,
        data: renderedRows,
        initialState: { density: 'compact', pagination: { pageSize: 50 } },
    });

    return (
        <div className={locale === "en" ? "ltr" : "rtl"}>
            {loading && <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
            >
                <CircularProgress color="inherit" />
            </Backdrop>}
            <Stack direction='row' sx={{ justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography sx={{ fontSize: '20px', fontWeight: 'bold', mb: '20px' }}>
                    {t('Rooms')}
                </Typography>
                <Stack onClick={handleAddClick} direction='row' className='addBtn' >
                    <AddIcon sx={{ mr: 1, fontSize: "14px" }} />
                    <Typography sx={{ fontSize: "12px" }}>{t("Add")}</Typography>
                </Stack>
            </Stack>
            {roomTypes.length > 0 && (
                <MaterialReactTable table={table} />
            )}

            {/* ================ add dialog ================ */}
            <RoomsTableAddCrud showAddDialog={showAddDialog} setShowAddDialog={setShowAddDialog} selectedAmenities={selectedAmenities} setSelectedAmenities={setSelectedAmenities} setRoomtype={setRoomtype} setRunUseEffect={setRunUseEffect} roomTypes={roomTypes} amenities={amenities} handleCheckboxChange={handleCheckboxChange} handleEditImageChange={handleEditImageChange} imagePreviews={imagePreviews} handleEditRemoveImage={handleEditRemoveImage} selectedImages={selectedImages} setSelectedImages={setSelectedImages} />
            {/* ================ add dialog ================ */}

            {/* ================ edit dialog ================ */}
            <RoomsTableEditCrud showEditDialog={showEditDialog} setShowEditDialog={setShowEditDialog} selectedImages={selectedImages} setSelectedImages={setSelectedImages} setImagePreviews={setImagePreviews} updatedRows={updatedRows} setUpdatedRows={setUpdatedRows} selectedAmenities={selectedAmenities} imagePreviews={imagePreviews} roomtype={roomtype} setRoomtype={setRoomtype} rowToEdit={rowToEdit} setRunUseEffect={setRunUseEffect} roomTypes={roomTypes} amenities={amenities} handleCheckboxChange={handleCheckboxChange} handleEditImageChange={handleEditImageChange} handleEditRemoveImage={handleEditRemoveImage} />
            {/* ================ edit dialog ================ */}

            {/* ================ delete dialog ================ */}
            <Dialog
                dir={locale === "en" ? "ltr" : "rtl"}
                // className={locale === "en" ? "ltr" : "rtl"}
                open={showDeleteDialog}
                onClose={handleDeleteClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t('Are you sure you want to delete this item?')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className='dialogDelete' sx={{ padding: "0 0 10px !important", }} id="alert-dialog-description">
                        {t('This item will be permanently deleted.')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleDeleteClose} sx={{ textTransform: 'capitalize' }}>{t('Disagree')}</Button>
                    <Button className='dialogDeleteBtn' variant="contained" color="error" onClick={handleDeleteConfirm} sx={{ textTransform: 'capitalize' }} autoFocus>
                        {t('Agree')}
                    </Button>
                </DialogActions>
            </Dialog>
            {/* ================ delete dialog ================ */}

        </div>
    )
}
