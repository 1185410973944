import React from 'react'
import { Container } from '@mui/material';
import { Typography } from '@mui/material'

import { useTranslation } from 'react-i18next';
import { LocaleContext } from '../contexts/LocaleContext';
import { useContext} from 'react';

import SettingFirst from '../settings/SettingFirst';
import SettingSecond from '../settings/SettingSecond';
import SettingThird from '../settings/SettingThird';
import SettingLogo from '../settings/SettingLogo';
import SettingFour from '../settings/SettingFour';

export default function Setting() {
  const [t, i18n] = useTranslation("global");
  const { locale, setLocale } = useContext(LocaleContext);

  return (
    <div>
      <Container maxWidth="xl">
        <Typography sx={{ fontSize: '20px', fontWeight: 'bold', mb: '20px' }}>
          {t('Settings')}
        </Typography>
        <SettingFirst />
        <SettingSecond />
        <SettingThird />
        <SettingLogo />
        <SettingFour />
      </Container>
    </div>
  )
}
