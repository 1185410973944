import { Container } from '@mui/material';
import AmenitiesTable from '../component/AmenitiesTable';

export default function Amenities() {
  return (
    <div>
      <Container maxWidth="xl">
        <AmenitiesTable />
      </Container>
    </div>
  );
}
