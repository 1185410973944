import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';

import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastContext } from '../../contexts/ToastContext';
import { LocaleContext } from '../../contexts/LocaleContext';
import { useTheme } from '@mui/material/styles';

export default function TranscationEditCrud({showEditDialog, setShowEditDialog, updatedRows, setUpdatedRows, roomtype, setRoomtype, rowToEdit, setRunUseEffect, booking, user}) {
    const { locale, setLocale } = useContext(LocaleContext);
    const { showHideToast } = useContext(ToastContext);
    const [t] = useTranslation("global");
    const theme = useTheme({
        Typography: {
            fontFamily: [
                'Almarai',
            ].join(','),
        }
    });

    const [loading, setLoading] = useState(false);
    const handleClose = () => {
        setLoading(false);
    };
    const handleOpen = () => {
        setLoading(true);
    };

    //  ================ edit function ================
    function handleEditClose() {
        setShowEditDialog(false);
    }
    function handleEditSubmit(event) {
        event.preventDefault();
        handleOpen();

        const url = `https://panorama.site-pocket.com/api/transactions/${rowToEdit}`;
        const params = {
            booking_id: updatedRows.BookingId,
            user_id: updatedRows.UserId,
            transaction_id: updatedRows.TransactionId,
            payment_method: updatedRows.PaymentMethod,
            amount: updatedRows.Amount,
            status: updatedRows.Status,
        };
        const tokenString = localStorage.getItem('auth');
        const tokenObject = JSON.parse(tokenString);
        const token = tokenObject.accessToken;

        axios.put(url, params, {
            headers: { Authorization: `Bearer ${token}` },
        })
            .then(function (response) {
                const updatedRowsArray = roomtype.map((row) => {
                    if (row.id === rowToEdit) {
                        return {
                            ...row,
                            booking_id: updatedRows.BookingId,
                            user_id: updatedRows.UserId,
                            transaction_id: updatedRows.TransactionId,
                            payment_method: updatedRows.PaymentMethod,
                            amount: updatedRows.Amount,
                            status: updatedRows.Status,
                        };
                    }
                    return row;
                });
                setRoomtype(updatedRowsArray);
                setShowEditDialog(false);
                showHideToast(t("edited successfully"));
                setRunUseEffect((prev) => prev + 1);
                handleClose();
            })
            .catch(function (error) {
                console.log(error);
                let errorMessage = 'An error occurred. Please try again.';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = 'Bad request. Please check your input.';
                    } else if (error.response.status === 401) {
                        errorMessage = 'Unauthorized. Please log in again.';
                    } else if (error.response.status === 403) {
                        errorMessage = 'Forbidden. You do not have permission to perform this action.';
                    } else if (error.response.status === 404) {
                        errorMessage = 'Resource not found.';
                    } else if (error.response.status === 500) {
                        errorMessage = 'Internal server error. Please try again later.';
                    } else if (error.response.data.message.includes('The amount field must be a number')) {
                        errorMessage = 'The amount field must be a number.';
                    } else if (error.response.data.message.includes('The transaction id has already been taken')) {
                        errorMessage = 'The transaction id has already been taken.';
                    } else {
                        errorMessage = 'An unexpected error occurred. Please try again later.';
                    }
                }
                handleClose();
                showHideToast(errorMessage, "error");
            });
    }
    //  ================ edit function ================

    return (
        <div>
            {loading && <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
            >
                <CircularProgress color="inherit" />
            </Backdrop>}
            <Dialog
                dir={locale === "en" ? "ltr" : "rtl"}
                className={theme.palette.mode === 'light' ? 'light' : 'dark'}
                open={showEditDialog}
                onClose={handleEditClose}
            >
                <DialogTitle sx={{ pb: 1 }}>{t('Edit')}</DialogTitle>
                <DialogContent sx={{ pt: '10px !important', pb: '0px !important' }}>
                    <TextField value={updatedRows.TransactionId} onChange={(e) => setUpdatedRows({ ...updatedRows, TransactionId: e.target.value })} sx={{ mb: 2 }} fullWidth label={t("Transaction Id")} type="text" size="small" required />
                    <TextField value={updatedRows.PaymentMethod} onChange={(e) => setUpdatedRows({ ...updatedRows, PaymentMethod: e.target.value })} sx={{ mb: 2 }} fullWidth label={t("Payment Method")} type="text" size="small" required />
                    <TextField value={updatedRows.Amount} onChange={(e) => setUpdatedRows({ ...updatedRows, Amount: e.target.value })} sx={{ mb: 2 }} fullWidth label={t("Amount")} type="text" size="small" required />
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel>{t('Booking Id')}</InputLabel>
                        <Select
                            size="small"
                            value={updatedRows.BookingId}
                            onChange={(e) => setUpdatedRows({ ...updatedRows, BookingId: e.target.value })}
                            name="BookingId"
                            required
                        >
                            {booking.map(roomType => (
                                <MenuItem key={roomType.id} value={roomType.id}>{roomType.booking_number}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel>{t('User Id')}</InputLabel>
                        <Select
                            size="small"
                            value={updatedRows.UserId}
                            onChange={(e) => setUpdatedRows({ ...updatedRows, UserId: e.target.value })}
                            name="UserId"
                            required
                        >
                            {user.map(roomType => (
                                <MenuItem key={roomType.id} value={roomType.id}>{roomType.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel>{t('Status')}</InputLabel>
                        <Select
                            size="small"
                            value={updatedRows.Status}
                            onChange={(e) => {
                                setUpdatedRows({ ...updatedRows, Status: e.target.value })
                            }}
                            name="Status"
                            required
                        >
                            <MenuItem value="pending">pending</MenuItem>
                            <MenuItem value="success">success</MenuItem>
                            <MenuItem value="failed">failed</MenuItem>
                        </Select>
                    </FormControl>

                </DialogContent>
                <DialogActions>
                    <Button sx={{ textTransform: 'capitalize' }} variant="contained" color="error" onClick={handleEditClose}>{t('Cancel')}</Button>
                    <Button className='dialogDeleteBtn' onClick={handleEditSubmit} sx={{ textTransform: 'capitalize' }} variant="contained" type="submit">{t('Confirm')}</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
