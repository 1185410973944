import { Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ClearIcon from '@mui/icons-material/Clear';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import axios from 'axios';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastContext } from '../../contexts/ToastContext';
import { LocaleContext } from '../../contexts/LocaleContext';
import { useTheme } from '@mui/material/styles';

export default function BookingAddCrud({ setShowAddDialog, setRoomtype, setRunUseEffect, showAddDialog, user, rooms }) {
    const { locale, setLocale } = useContext(LocaleContext);
    const { showHideToast } = useContext(ToastContext);
    const [t] = useTranslation("global");
    const theme = useTheme({
        Typography: {
            fontFamily: [
                'Almarai',
            ].join(','),
        }
    });

    const [loading, setLoading] = useState(false);
    const handleClose = () => {
        setLoading(false);
    };
    const handleOpen = () => {
        setLoading(true);
    };

    //  ================ add state ================
    const [addInput, setAddInput] = useState({
        UserName: '',
        TotalPrice: '',
        Status: '',
        rooms: [{ room_id: '', check_in_date: '', check_out_date: '' }]
    })
    //  ================ add state ================

    //  ================ add function ================
    function handleAddClose() {
        setShowAddDialog(false);
    }

    const handleRoomChange = (event, index) => {
        const updatedRooms = [...addInput.rooms];
        updatedRooms[index].room_id = event.target.value;
        setAddInput({ ...addInput, rooms: updatedRooms });
    };

    const handleCheckInChange = (event, index) => {
        const updatedRooms = [...addInput.rooms];
        updatedRooms[index].check_in_date = event.target.value;
        setAddInput({ ...addInput, rooms: updatedRooms });
    };

    const handleCheckOutChange = (event, index) => {
        const updatedRooms = [...addInput.rooms];
        updatedRooms[index].check_out_date = event.target.value;
        setAddInput({ ...addInput, rooms: updatedRooms });
    };

    const handleAddRoom = () => {
        setAddInput({
            ...addInput,
            rooms: [...addInput.rooms, { room_id: '', check_in_date: '', check_out_date: '' }]
        });
    };

    const handleRemoveRoom = (index) => {
        const updatedRooms = [...addInput.rooms];
        updatedRooms.splice(index, 1); // Remove the room at the specified index
        setAddInput({ ...addInput, rooms: updatedRooms });
    };

    async function handleDialogSubmit(event) {
        event.preventDefault();
        handleOpen();
        const url = 'https://panorama.site-pocket.com/api/bookings';

        const roomsData = addInput.rooms.map(room => ({
            room_id: room.room_id,
            check_in_date: room.check_in_date,
            check_out_date: room.check_out_date
        }));

        const params = {
            total_price: addInput.TotalPrice,
            status: addInput.Status,
            user_id: addInput.UserName,
            rooms: roomsData
        };
        const tokenString = localStorage.getItem('auth');
        const tokenObject = JSON.parse(tokenString);
        const token = tokenObject.accessToken;

        try {
            const response = await axios.post(url, params, {
                headers: { 'Authorization': 'Bearer ' + token },
            });
            if (response.status === 201) {
                const newRow = {
                    id: response.data.booking.id,
                    total_price: addInput.TotalPrice,
                    status: addInput.Status,
                    user_id: addInput.UserName,
                    rooms: roomsData
                };
                setRoomtype(prevRows => [...prevRows, newRow]); // Update roomtype state with new data
                showHideToast(t("added successfully"));
                setShowAddDialog(false);
                setAddInput({
                    UserName: '',
                    TotalPrice: '',
                    Status: '',
                    rooms: [{ room_id: '', check_in_date: '', check_out_date: '' }]
                });
                setRunUseEffect((prev) => prev + 1);
                handleClose();
            }
        } catch (error) {
            let errorMessage = 'An error occurred. Please try again.';
            if (error.response) {
                if (error.response.status === 400) {
                    errorMessage = 'Bad request. Please check your input.';
                } else if (error.response.status === 401) {
                    errorMessage = 'Unauthorized. Please log in again.';
                } else if (error.response.status === 403) {
                    errorMessage = 'Forbidden. You do not have permission to perform this action.';
                } else if (error.response.status === 404) {
                    errorMessage = 'Resource not found.';
                } else if (error.response.status === 500) {
                    errorMessage = 'Internal server error. Please try again later.';
                } else if (error.response.data.message.includes('check_out_date field must be a date after rooms')) {
                    errorMessage = 'Check-out date must be a date after the check-In date.';
                } else if (error.response.data.message.includes('The total price field must be a number')) {
                    errorMessage = 'The total price field must be a number.';
                } else {
                    errorMessage = 'An unexpected error occurred. Please try again later.';
                }
            }
            handleClose();
            showHideToast(errorMessage, "error");
        }
    }
    //  ================ add function ================


    return (
        <div>
            {loading && <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
            >
                <CircularProgress color="inherit" />
            </Backdrop>}
            <Dialog
                className={theme.palette.mode === 'light' ? 'light' : 'dark'}
                dir={locale === "en" ? "ltr" : "rtl"}
                open={showAddDialog}
                onClose={handleAddClose}
                sx={{ margin: "auto" }}
            >
                <DialogTitle sx={{ pb: 0 }}>{t('Add')}</DialogTitle>
                <DialogContent sx={{ pt: '10px !important' }}>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel>{t('User Name')}</InputLabel>
                        <Select
                            size="small"
                            value={addInput.UserName}
                            onChange={(e) => setAddInput({ ...addInput, UserName: e.target.value })}
                            name="UserName"
                            required
                        >
                            {user.map(roomType => (
                                <MenuItem key={roomType.id} value={roomType.id}>{roomType.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        size="small"
                        sx={{ mb: 2 }}
                        type='text'
                        fullWidth
                        required
                        name='TotalPrice'
                        label={t("Total Price")}
                        value={addInput.TotalPrice}
                        onChange={(e) => {
                            setAddInput({ ...addInput, TotalPrice: e.target.value })
                        }}
                    />
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel>{t('Status')}</InputLabel>
                        <Select
                            size='small'
                            value={addInput.Status}
                            onChange={(e) => {
                                setAddInput({ ...addInput, Status: e.target.value })
                            }}
                            name="Status"
                            required
                        >
                            <MenuItem value="confirmed">confirmed</MenuItem>
                            <MenuItem value="pending">pending</MenuItem>
                            <MenuItem value="cancelled">cancelled</MenuItem>
                        </Select>
                    </FormControl>
                    {addInput.rooms.map((room, index) => (
                        <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: "10px" }}>
                            <FormControl fullWidth>
                                <InputLabel>{t('Room No')}</InputLabel>
                                <Select
                                    size="small"
                                    value={room.room_id}
                                    onChange={(e) => handleRoomChange(e, index)}
                                    name="roomNo"
                                    required
                                >
                                    {rooms.map(roomType => (
                                        <MenuItem key={roomType.id} value={roomType.id}>{roomType.room_number}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <TextField
                                size="small"
                                type='date'
                                fullWidth
                                required
                                name='CheckInDate'
                                label={t("Check-in Date")}
                                value={room.check_in_date}
                                onChange={(e) => handleCheckInChange(e, index)}
                            />
                            <TextField
                                size="small"
                                type='date'
                                fullWidth
                                required
                                name='CheckOutDate'
                                label={t("Check-out Date")}
                                value={room.check_out_date}
                                onChange={(e) => handleCheckOutChange(e, index)}
                            />
                            <IconButton color="error" onClick={() => handleRemoveRoom(index)} aria-label="remove-room">
                                <ClearIcon />
                            </IconButton>
                        </div>
                    ))}
                    <Button sx={{ mt: 2, textTransform: 'capitalize' }} variant="contained" color="primary" onClick={handleAddRoom}>
                        {t("Add Room")}
                    </Button>
                </DialogContent>

                <DialogActions>
                    <Button sx={{ textTransform: 'capitalize' }} variant="contained" color="error" onClick={handleAddClose}>{t("Cancel")}</Button>
                    <Button
                        sx={{ textTransform: 'capitalize' }}
                        variant="contained"
                        disabled={!addInput.UserName || !addInput.TotalPrice || !addInput.Status || addInput.rooms.length === 0 || addInput.rooms.some(room => !room.room_id || !room.check_in_date || !room.check_out_date)}
                        onClick={handleDialogSubmit} type="submit">{t("Submit")}</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
