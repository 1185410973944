import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Avatar from '@mui/material/Avatar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';


import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastContext } from '../../contexts/ToastContext';
import { LocaleContext } from '../../contexts/LocaleContext';
import { useTheme } from '@mui/material/styles';


export default function AmenitiesEditCrud({ showEditDialog, setShowEditDialog, setRoomtype, roomtype, rowToEdit, updatedRows, setUpdatedRows }) {
    const { locale, setLocale } = useContext(LocaleContext);
    const { showHideToast } = useContext(ToastContext);

    const [t] = useTranslation("global");
    const theme = useTheme({
        Typography: {
            fontFamily: [
                'Almarai',
            ].join(','),
        }
    });

    const [loading, setLoading] = useState(false);
    const handleClose = () => {
        setLoading(false);
    };
    const handleOpen = () => {
        setLoading(true);
    };

    //  ================ edit function ================
    function handleEditClose() {
        setShowEditDialog(false);
    }

    const handleImageEditChange = (event) => {
        const imageFile = event.target.files[0];
        const imagePreviewURL = URL.createObjectURL(imageFile);
        setUpdatedRows({
            ...updatedRows,
            Image: imageFile,
            ImagePreview: imagePreviewURL
        });
    };

    function handleEditSubmit(event) {
        event.preventDefault();
        handleOpen();

        const url = `https://panorama.site-pocket.com/api/amenities/${rowToEdit}`;
        let formData = new FormData();
        formData.append('name_en', updatedRows.NameEN);
        formData.append('name_ar', updatedRows.NameAR);

        // Append _method parameter with value PUT
        formData.append('_method', 'PUT');

        // Only append image data if it's not null
        if (updatedRows.Image !== null) {
            formData.append('image_path', updatedRows.Image);
        }

        const tokenString = localStorage.getItem('auth');
        const tokenObject = JSON.parse(tokenString);
        const token = tokenObject.accessToken;

        axios.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(function (response) {
                const updatedAmenity = response.data.amenity;

                const updatedRowsArray = roomtype.map((row) => {
                    if (row.id === rowToEdit) {
                        return {
                            ...row,
                            name_en: updatedRows.NameEN,
                            name_ar: updatedRows.NameAR,
                            image_path: updatedAmenity.image_path
                        };
                    }
                    return row;
                });
                setRoomtype(updatedRowsArray);
                setShowEditDialog(false);
                showHideToast(t("edited successfully"));
                handleClose();
            })
            .catch(function (error) {
                let errorMessage = 'An error occurred. Please try again.';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = 'Bad request. Please check your input.';
                    } else if (error.response.status === 401) {
                        errorMessage = 'Unauthorized. Please log in again.';
                    } else if (error.response.status === 403) {
                        errorMessage = 'Forbidden. You do not have permission to perform this action.';
                    } else if (error.response.status === 404) {
                        errorMessage = 'Resource not found.';
                    } else if (error.response.status === 500) {
                        errorMessage = 'Internal server error. Please try again later.';
                    } else if (error.response.data.message.includes('The name en field must be a string')) {
                        errorMessage = 'you need to enter Name EN.';
                    } else if (error.response.data.message.includes('The name ar field must be a string')) {
                        errorMessage = 'you need to enter Name AR.';
                    } else {
                        errorMessage = 'An unexpected error occurred. Please try again later.';
                    }
                }
                handleClose();
                showHideToast(errorMessage, "error");
            });
    }
    //  ================ edit function ================

    return (
        <div>
            {loading && <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
            >
                <CircularProgress color="inherit" />
            </Backdrop>}
            <Dialog
                dir={locale === "en" ? "ltr" : "rtl"}
                // className={locale === "en" ? "ltr" : "rtl"}
                open={showEditDialog}
                onClose={handleEditClose}
            >
                <DialogTitle sx={{ pb: 1 }}>{t('Edit')}</DialogTitle>
                <DialogContent sx={{ pt: '10px !important', pb: '0px !important' }}>
                    <TextField value={updatedRows.NameEN} onChange={(e) => setUpdatedRows({ ...updatedRows, NameEN: e.target.value })} sx={{ mb: 2 }} fullWidth label={t("Name EN")} type="text" size="small" />
                    <TextField value={updatedRows.NameAR} onChange={(e) => setUpdatedRows({ ...updatedRows, NameAR: e.target.value })} sx={{ mb: 2 }} fullWidth label={t("Name AR")} type="text" size="small" />
                    <input type="file" accept="image/*" style={{ display: 'none' }} onChange={handleImageEditChange} />
                    <Button variant="outlined" component="label" sx={{ textTransform: 'capitalize' }}>
                        {t('Choose Image')}
                        <input type="file" accept="image/*" style={{ display: 'none' }} onChange={handleImageEditChange} />
                    </Button>
                    <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                        {updatedRows.ImagePreview && (
                            <div style={{ marginRight: '10px', marginBottom: '10px', width: '80px', height: '80px', backgroundColor: theme.palette.mode === 'dark' ? 'white' : null, borderRadius: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Avatar variant="square" sx={{ width: '60px', height: '60px', margin: 'auto' }} alt="Preview" src={updatedRows.ImagePreview} />
                            </div>
                        )}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button sx={{ textTransform: 'capitalize' }} variant="contained" color="error" onClick={handleEditClose}>{t('Cancel')}</Button>
                    <Button className='dialogDeleteBtn' onClick={handleEditSubmit} sx={{ textTransform: 'capitalize' }} variant="contained" type="submit">{t('Confirm')}</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
