import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Avatar from '@mui/material/Avatar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import axios from 'axios';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastContext } from '../../contexts/ToastContext';
import { LocaleContext } from '../../contexts/LocaleContext';
import { useTheme } from '@mui/material/styles';

export default function BookingEditCrud({ setShowEditDialog, rowToEdit, updatedRows, roomtype, setRoomtype, showEditDialog, setUpdatedRows }) {
    const { locale, setLocale } = useContext(LocaleContext);
    const { showHideToast } = useContext(ToastContext);
    const [t] = useTranslation("global");
    const theme = useTheme({
        Typography: {
            fontFamily: [
                'Almarai',
            ].join(','),
        }
    });

    const [loading, setLoading] = useState(false);
    const handleClose = () => {
        setLoading(false);
    };
    const handleOpen = () => {
        setLoading(true);
    };

    //  ================ edit function ================
    function handleEditClose() {
        setShowEditDialog(false);
    }

    function handleEditSubmit(event) {
        event.preventDefault();
        handleOpen();

        const url = `https://panorama.site-pocket.com/api/bookings/${rowToEdit}`;

        const params = {
            status: updatedRows.Status,
        };
        const tokenString = localStorage.getItem('auth');
        const tokenObject = JSON.parse(tokenString);
        const token = tokenObject.accessToken;

        axios.put(url, params, {
            headers: { Authorization: `Bearer ${token}` },
        })
            .then(function (response) {
                const updatedRowsArray = roomtype.map((row) => {
                    if (row.id === rowToEdit) {
                        return {
                            ...row,
                            status: updatedRows.Status,
                        };
                    }
                    return row;
                });
                setRoomtype(updatedRowsArray);
                setShowEditDialog(false);
                showHideToast(t("edited successfully"));
                handleClose();
            })
            .catch(function (error) {
                let errorMessage = 'An error occurred. Please try again.';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = 'Bad request. Please check your input.';
                    } else if (error.response.status === 401) {
                        errorMessage = 'Unauthorized. Please log in again.';
                    } else if (error.response.status === 403) {
                        errorMessage = 'Forbidden. You do not have permission to perform this action.';
                    } else if (error.response.status === 404) {
                        errorMessage = 'Resource not found.';
                    } else if (error.response.status === 500) {
                        errorMessage = 'Internal server error. Please try again later.';
                    } else {
                        errorMessage = 'An unexpected error occurred. Please try again later.';
                    }
                }
                handleClose();
                showHideToast(errorMessage, "error");
            });
    }

    //  ================ edit function ================
    return (
        <div>
            {loading && <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
            >
                <CircularProgress color="inherit" />
            </Backdrop>}
            <Dialog
                dir={locale === "en" ? "ltr" : "rtl"}
                className={theme.palette.mode === 'light' ? 'light' : 'dark'}
                open={showEditDialog}
                onClose={handleEditClose}
            >
                <DialogTitle sx={{ pb: 1 }}>{t('Edit')}</DialogTitle>
                <DialogContent sx={{ pt: '10px !important', pb: '0px !important' }}>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel>{t('Status')}</InputLabel>
                        <Select
                            size='small'
                            value={updatedRows.Status || ''}
                            onChange={(e) => {
                                setUpdatedRows({ ...updatedRows, Status: e.target.value })
                            }}
                            name="Status"
                            required
                        >
                            <MenuItem value="confirmed">confirmed</MenuItem>
                            <MenuItem value="pending">pending</MenuItem>
                            <MenuItem value="cancelled">cancelled</MenuItem>
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button sx={{ textTransform: 'capitalize' }} variant="contained" color="error" onClick={handleEditClose}>{t('Cancel')}</Button>
                    <Button className='dialogDeleteBtn' onClick={handleEditSubmit} sx={{ textTransform: 'capitalize' }} variant="contained" type="submit">{t('Confirm')}</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
