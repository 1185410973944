import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastContext } from '../../contexts/ToastContext';
import { LocaleContext } from '../../contexts/LocaleContext';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';

export default function RoomTypesAddCrud({ showAddDialog, setShowAddDialog, setRoomtype }) {
  const { locale, setLocale } = useContext(LocaleContext);
  const { showHideToast } = useContext(ToastContext);
  const [t] = useTranslation("global");
  const theme = useTheme({
    Typography: {
      fontFamily: [
        'Almarai',
      ].join(','),
    }
  });

  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    setLoading(false);
  };
  const handleOpen = () => {
    setLoading(true);
  };

  //  ====== open && close add state ========
  const [addInput, setAddInput] = useState({
    NameEN: '',
    NameAR: '',
  })
  //  ====== open && close add state ========

  //  ================ add function ================
  function handleAddClose() {
    setShowAddDialog(false);
  }

  function handleDialogSubmit(event) {
    event.preventDefault();
    handleOpen();

    const url = 'https://panorama.site-pocket.com/api/room-types';
    const params = {
      name_en: addInput.NameEN,
      name_ar: addInput.NameAR,
    };

    const tokenString = localStorage.getItem('auth');
    const tokenObject = JSON.parse(tokenString);
    const token = tokenObject.accessToken;

    axios.post(url, params, {
      headers: { 'Authorization': 'Bearer ' + token },
    })
      .then(function (response) {
        const newRow = {
          id: response.data.roomType.id,
          name_en: addInput.NameEN,
          name_ar: addInput.NameAR,
        };
        setRoomtype((prevRows) => [...prevRows, newRow]); // Update roomtype state with new data
        showHideToast(t("added successfully"));
        setShowAddDialog(false);
        setAddInput({
          NameEN: "",
          NameAR: "",
        });
        handleClose();
      })
      .catch(function (error) {
        // Handle errors here
        let errorMessage = 'An error occurred while creating the resource. Please try again.';
        if (error.response) {
          if (error.response.status === 400) {
            errorMessage = 'Bad request. Please check your input data and try again.';
          } else if (error.response.status === 401) {
            errorMessage = 'Unauthorized. Please login to perform this action.';
          } else if (error.response.status === 403) {
            errorMessage = 'Forbidden. You do not have permission to perform this action.';
          } else if (error.response.status === 409) {
            errorMessage = 'Conflict. The resource already exists.';
          } else if (error.response.status === 500) {
            errorMessage = 'Internal server error. Please try again later.';
          } else {
            errorMessage = 'An unexpected error occurred. Please try again later.';
          }
        }
        // console.error("Error creating resource:", error);
        handleClose();
        showHideToast(errorMessage, "error");
      });
  }

  //  ================ add function ================

  return (
    <div>
      {loading && <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>}
      {/* ================ add dialog ================ */}
      <Dialog
        className={theme.palette.mode === 'light' ? 'light' : 'dark'}
        dir={locale === "en" ? "ltr" : "rtl"}
        open={showAddDialog}
        onClose={handleAddClose}
      >
        <DialogTitle sx={{ pb: 0 }}>{t('Add')}</DialogTitle>
        <DialogContent sx={{ pt: '10px !important' }}>
          <TextField
            size="small"
            required
            type='text'
            sx={{ mb: 2, }}
            fullWidth
            label={t("Name EN")}
            name="NameEN"
            value={addInput.NameEN}
            onChange={(e) => {
              setAddInput({ ...addInput, NameEN: e.target.value })
            }}
          />
          <TextField
            size="small"
            required
            sx={{ mb: 2 }}
            type='text'
            fullWidth
            name='NameAR'
            label={t("Name AR")}
            value={addInput.NameAR}
            onChange={(e) => {
              setAddInput({ ...addInput, NameAR: e.target.value })
            }}
          />
        </DialogContent>

        <DialogActions>
          <Button sx={{ textTransform: 'capitalize' }} variant="contained" color="error" onClick={handleAddClose}>{t("Cancel")}</Button>
          <Button
            sx={{ textTransform: 'capitalize' }}
            variant="contained"
            disabled={!addInput.NameEN || !addInput.NameAR}
            onClick={handleDialogSubmit} type="submit">{t("Submit")}</Button>
        </DialogActions>
      </Dialog>
      {/* ================ add dialog ================ */}
    </div>
  )
}
