import { Container } from '@mui/material';
import RoomTypesTable from '../component/RoomTypesTable';


export default function RoomTypes() {
  return (
    <div>
      <Container maxWidth="xl">
        <RoomTypesTable />    
      </Container>
    </div>
  )
}
