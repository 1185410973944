import React, { useContext } from 'react';
import Row1 from '../rows/Row1';
import Row2 from '../rows/Row2';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LocaleContext } from '../contexts/LocaleContext';
import { Container } from '@mui/material';


export default function Dashboard() {
  const [t, i18n] = useTranslation('global');
  const { locale, setLocale } = useContext(LocaleContext);

  return (
    <Box dir={locale === 'en' ? 'ltr' : 'rtl'}>
      <Container maxWidth="xl">
        <Typography sx={{ fontSize: '20px', fontWeight: 'bold', mb: '20px' }}>
          {t('Dashboard')}
        </Typography>
        <Row1 />
        <Row2 />
      </Container>
    </Box>
  );
}
