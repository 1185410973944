import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';

import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import NavbarSide from './component/NavbarSide';

import './App.css';
import { Outlet, useLocation } from 'react-router-dom';

// theme
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getDesignTokens } from './Theme';

// hook
import { useState, useContext } from 'react';

// context
import { LocaleContext } from './contexts/LocaleContext';


const drawerWidth = 240;
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

export default function App() {
  const { locale, setLocale } = useContext(LocaleContext);
  const [mode, setMode] = React.useState(localStorage.getItem("currentMode") ? localStorage.getItem("currentMode") : "light");
  const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);


  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  // to make login page appear without sidebar
  const location = useLocation(); // Get current location
  const isLoginPage = location.pathname === "/login";
  const isProtectedRoute = !isLoginPage && location.pathname !== "*";

  return (
    <ThemeProvider theme={theme}>
      {/* <Box sx={{ display: 'flex' }} dir={locale === "en" ? "ltr" : "rtl"}> */}
      <Box sx={{ display: 'flex', width: '100vw' }} dir={locale === "en" ? "ltr" : "rtl"}>
        <CssBaseline />
        {/* ======= open&Close ======= */}
        {/* to make login page appear without sidebar */}
        {!isLoginPage && isProtectedRoute && (
          <Toolbar >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        )}
        {/* ======= Drawer ======= */}
        {!isLoginPage && isProtectedRoute && (
        <NavbarSide open={open} handleDrawerClose={handleDrawerClose} setMode={setMode} />
        )}
        {/* ======= Content ======= */}
        <Main className={theme.palette.mode === 'light' ? 'light' : 'dark'} sx={{ overflow: 'hidden', padding: "24px 0", minWidth: { sm: "600px", md: "900px", lg: "1200px" } }} open={open}>
          <Outlet />
        </Main>
      </Box>
    </ThemeProvider>
  );
}
