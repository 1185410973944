import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';

import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastContext } from '../../contexts/ToastContext';
import { LocaleContext } from '../../contexts/LocaleContext';
import { useTheme } from '@mui/material/styles';

export default function TranscationAddCrud({ showAddDialog, setShowAddDialog, setRoomtype, setRunUseEffect, booking, user }) {
    const { locale, setLocale } = useContext(LocaleContext);
    const { showHideToast } = useContext(ToastContext);
    const [t] = useTranslation("global");
    const theme = useTheme({
        Typography: {
            fontFamily: [
                'Almarai',
            ].join(','),
        }
    });

    const [loading, setLoading] = useState(false);
    const handleClose = () => {
        setLoading(false);
    };
    const handleOpen = () => {
        setLoading(true);
    };

    //  ====== open && close add state ========
    const [addInput, setAddInput] = useState({
        BookingId: '',
        UserId: '',
        TransactionId: '',
        PaymentMethod: '',
        Amount: '',
        Status: '',
    })
    //  ====== open && close add state ========

    //  ================ add function ================
    function handleAddClose() {
        setShowAddDialog(false);
    }

    function handleDialogSubmit(event) {
        event.preventDefault();
        handleOpen();
        const url = 'https://panorama.site-pocket.com/api/transactions';
        const params = {
            booking_id: addInput.BookingId,
            user_id: addInput.UserId,
            transaction_id: addInput.TransactionId,
            payment_method: addInput.PaymentMethod,
            amount: addInput.Amount,
            status: addInput.Status,
        };
        const tokenString = localStorage.getItem('auth');
        const tokenObject = JSON.parse(tokenString);
        const token = tokenObject.accessToken;

        axios.post(url, params, {
            headers: { 'Authorization': 'Bearer ' + token },
        })
            .then(function (response) {
                const newRow = {
                    id: response.data.transaction.id,
                    booking_id: addInput.BookingId,
                    user_id: addInput.UserId,
                    transaction_id: addInput.TransactionId,
                    payment_method: addInput.PaymentMethod,
                    amount: addInput.Amount,
                    status: addInput.Status,
                };
                setRoomtype(prevRows => [...prevRows, newRow]); // Update roomtype state with new data
                showHideToast(t("added successfully"));
                setShowAddDialog(false);
                setAddInput({
                    BookingId: '',
                    UserId: '',
                    TransactionId: '',
                    PaymentMethod: '',
                    Amount: '',
                    Status: '',
                });
                setRunUseEffect((prev) => prev + 1);
                handleClose();
            })
            .catch(function (error) {
                let errorMessage = 'An error occurred. Please try again.';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = 'Bad request. Please check your input.';
                    } else if (error.response.status === 401) {
                        errorMessage = 'Unauthorized. Please log in again.';
                    } else if (error.response.status === 403) {
                        errorMessage = 'Forbidden. You do not have permission to perform this action.';
                    } else if (error.response.status === 404) {
                        errorMessage = 'Resource not found.';
                    } else if (error.response.status === 500) {
                        errorMessage = 'Internal server error. Please try again later.';
                    } else if (error.response.data.message.includes('The amount field must be a number')) {
                        errorMessage = 'The amount field must be a number.';
                    } else {
                        errorMessage = 'An unexpected error occurred. Please try again later.';
                    }
                }
                handleClose();
                showHideToast(errorMessage, "error");
            });
    }
    //  ================ add function ================

    return (
        <div>
            {loading && <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
            >
                <CircularProgress color="inherit" />
            </Backdrop>}
            <Dialog
                className={theme.palette.mode === 'light' ? 'light' : 'dark'}
                dir={locale === "en" ? "ltr" : "rtl"}
                open={showAddDialog}
                onClose={handleAddClose}
                sx={{ width: "500px", margin: "auto" }}
            >
                <DialogTitle sx={{ pb: 0 }}>{t('Add')}</DialogTitle>
                <DialogContent sx={{ pt: '10px !important' }}>
                    <TextField
                        required
                        size="small"
                        type='text'
                        sx={{ mb: 2, }}
                        fullWidth
                        label={t("Transaction Id")}
                        name="TransactionId"
                        value={addInput.TransactionId}
                        onChange={(e) => {
                            setAddInput({ ...addInput, TransactionId: e.target.value })
                        }}
                    />
                    <TextField
                        required
                        size="small"
                        sx={{ mb: 2 }}
                        type='text'
                        fullWidth
                        name='PaymentMethod'
                        label={t("Payment Method")}
                        value={addInput.PaymentMethod}
                        onChange={(e) => {
                            setAddInput({ ...addInput, PaymentMethod: e.target.value })
                        }}
                    />
                    <TextField
                        required
                        size="small"
                        sx={{ mb: 2 }}
                        type='text'
                        fullWidth
                        name='Amount'
                        label={t("Amount")}
                        value={addInput.Amount}
                        onChange={(e) => {
                            setAddInput({ ...addInput, Amount: e.target.value })
                        }}
                    />

                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel>{t('Booking Id')}</InputLabel>
                        <Select
                            size="small"
                            value={addInput.BookingId}
                            onChange={(e) => setAddInput({ ...addInput, BookingId: e.target.value })}
                            name="BookingId"
                            required
                        >
                            {booking.map(roomType => (
                                <MenuItem key={roomType.id} value={roomType.id}>{roomType.booking_number}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel>{t('User Id')}</InputLabel>
                        <Select
                            size="small"
                            value={addInput.UserId}
                            onChange={(e) => setAddInput({ ...addInput, UserId: e.target.value })}
                            name="UserId"
                            required
                        >
                            {user.map(roomType => (
                                <MenuItem key={roomType.id} value={roomType.id}>{roomType.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel>{t('Status')}</InputLabel>
                        <Select
                            size="small"
                            value={addInput.Status}
                            onChange={(e) => {
                                setAddInput({ ...addInput, Status: e.target.value })
                            }}
                            name="Status"
                            required
                        >
                            <MenuItem value="pending">pending</MenuItem>
                            <MenuItem value="success">success</MenuItem>
                            <MenuItem value="failed">failed</MenuItem>
                        </Select>
                    </FormControl>

                </DialogContent>

                <DialogActions>
                    <Button sx={{ textTransform: 'capitalize' }} variant="contained" color="error" onClick={handleAddClose}>{t("Cancel")}</Button>
                    <Button
                        sx={{ textTransform: 'capitalize' }}
                        variant="contained"
                        disabled={!addInput.TransactionId || !addInput.PaymentMethod || !addInput.Amount || !addInput.Status || !addInput.BookingId || !addInput.UserId}
                        onClick={handleDialogSubmit} type="submit">{t("Submit")}</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
