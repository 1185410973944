import { Box, Button, Stack } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import AddIcon from '@mui/icons-material/Add';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { red, green, orange } from '@mui/material/colors';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import axios from 'axios';

import { useMemo, useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastContext } from '../contexts/ToastContext';
import { LocaleContext } from '../contexts/LocaleContext';
import { useTheme } from '@mui/material/styles';
import UserTableAddCrud from './CRUD/UserTableAddCrud';
import UserTableEditCrud from './CRUD/UserTableEditCrud';

export default function UsersTable() {
    const [roomtype, setRoomtype] = useState([]);
    const { locale, setLocale } = useContext(LocaleContext);
    const { showHideToast } = useContext(ToastContext);
    const [runUseEffect, setRunUseEffect] = useState(0);
    const [t] = useTranslation("global");
    const theme = useTheme({
        Typography: {
            fontFamily: [
                'Almarai',
            ].join(','),
        }
    });

    const [loading, setLoading] = useState(false);
    const handleClose = () => {
        setLoading(false);
    };
    const handleOpen = () => {
        setLoading(true);
    };


    // get all data
    useEffect(() => {
        handleOpen();
        const url = 'https://panorama.site-pocket.com/api/users';
        const tokenString = localStorage.getItem('auth');
        const tokenObject = JSON.parse(tokenString);
        const token = tokenObject.accessToken;
        axios.get(url, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(function (response) {
                setRoomtype(response.data.users);
                handleClose();
            })
            .catch(function (error) {
                let errorMessage = 'An error occurred. Please try again.';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = 'Bad request. Please check your input.';
                    } else if (error.response.status === 401) {
                        errorMessage = 'Unauthorized. Please log in again.';
                    } else if (error.response.status === 403) {
                        errorMessage = 'Forbidden. You do not have permission to perform this action.';
                    } else if (error.response.status === 404) {
                        errorMessage = 'Resource not found.';
                    } else if (error.response.status === 500) {
                        errorMessage = 'Internal server error. Please try again later.';
                    } else {
                        errorMessage = 'An unexpected error occurred. Please try again later.';
                    }
                }
                handleClose();
                showHideToast(errorMessage, "error");
            });
    }, [runUseEffect]);

    //  ====== open && close add state ========
    const [showAddDialog, setShowAddDialog] = useState(false);
    //  ====== open && close add state ========

    //  ====== open && close edit state ========
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [rowToEdit, setRowToEdit] = useState(null);
    const [updatedRows, setUpdatedRows] = useState({
        Name: '',
        Email: '',
        Phone: '',
        Password: '',
        Nationality: '',
        Verified: false,
        Admin: false,
    });
    //  ====== open && close edit state ========

    //  ====== open && close delete state ========
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [rowToDelete, setRowToDelete] = useState(null);
    //  ====== open && close delete state ========

    //  ================ add function ================
    function handleAddClick() {
        setShowAddDialog(true);
    }
    //  ================ add function ================

    //  ================ edit function ================
    function handleEditClick(id) {
        const row = roomtype.find((row) => row.id === id);
        setUpdatedRows({
            Name: row.name,
            Email: row.email,
            Phone: row.phone,
            Password: row.password,
            Nationality: row.nationality,
            Verified: row.email_verified_at,
            Admin: row.is_admin,
        });
        setRowToEdit(id);
        setShowEditDialog(true);
    }
    //  ================ edit function ================

    //  ================ delete function ================
    function handleDeleteClick(id) {
        setRowToDelete(id);
        setShowDeleteDialog(true);
    }

    function handleDeleteClose() {
        setShowDeleteDialog(false);
    }

    function handleDeleteConfirm() {
        handleOpen();
        const url = `https://panorama.site-pocket.com/api/users/${rowToDelete}`;

        const tokenString = localStorage.getItem('auth');
        const tokenObject = JSON.parse(tokenString);
        const token = tokenObject.accessToken;

        axios.delete(url, {
            headers: { Authorization: `Bearer ${token}` },
        })
            .then(function (response) {
                const updatedRows = roomtype.filter((row) => row.id !== rowToDelete);
                setRoomtype(updatedRows);
                setShowDeleteDialog(false);
                showHideToast(t("deleted successfully"));
                handleClose();
            })
            .catch(function (error) {
                let errorMessage = 'An error occurred. Please try again.';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = 'Bad request. Please check your input.';
                    } else if (error.response.status === 401) {
                        errorMessage = 'Unauthorized. Please log in again.';
                    } else if (error.response.status === 403) {
                        errorMessage = 'Forbidden. You do not have permission to perform this action.';
                    } else if (error.response.status === 404) {
                        errorMessage = 'Resource not found.';
                    } else if (error.response.status === 500) {
                        errorMessage = 'Internal server error. Please try again later.';
                    } else {
                        errorMessage = 'An unexpected error occurred. Please try again later.';
                    }
                }
                handleClose();
                showHideToast(errorMessage, "error");
            });
    }

    //  ================ delete function ================
    const columns = useMemo(
        () => [
            {
                accessorKey: 'index',
                header: '#',
                size: 80,
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                },
                muiTableFooterCellProps: {
                    align: 'center',
                },
            },
            {
                accessorKey: 'name',
                header: (t('Name')),
                size: 150,
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                },
                muiTableFooterCellProps: {
                    align: 'center',
                },
            },
            {
                accessorKey: 'email',
                header: (t('Email')),
                size: 80,
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                },
                muiTableFooterCellProps: {
                    align: 'center',
                },
            },
            {
                accessorKey: 'phone',
                header: (t('Phone')),
                size: 80,
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                },
                muiTableFooterCellProps: {
                    align: 'center',
                },
            },
            {
                accessorKey: 'nationality',
                header: (t('Nationality Id')),
                size: 80,
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                },
                muiTableFooterCellProps: {
                    align: 'center',
                },
            },
            {
                accessorKey: 'email_verified_at',
                header: (t('Verified')),
                size: 80,
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                },
                muiTableFooterCellProps: {
                    align: 'center',
                },
                Cell: ({ cell }) => (
                    cell.getValue() ? (<CheckCircleOutlineIcon sx={{ color: green[500], margin: 'auto' }} />) : (<CancelOutlinedIcon sx={{ color: red[500], margin: 'auto' }} />)
                ),
            },
            {
                accessorKey: 'is_admin',
                header: (t('Admin')),
                size: 80,
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                },
                muiTableFooterCellProps: {
                    align: 'center',
                },
                Cell: ({ cell }) => (
                    <Typography align="center" sx={{ backgroundColor: cell.getValue() ? green[100] : red[100], color: cell.getValue() ? green[700] : red[700], border: `1px solid ${cell.getValue() ? green[300] : red[300]}`, borderRadius: '4px', width: '80px', margin: 'auto' }}>
                        {cell.getValue() ? t('Yes') : t('No')}
                    </Typography>
                ),
            },
            {
                accessorKey: 'Action',
                header: (t('Actions')),
                size: 150,
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                },
                muiTableFooterCellProps: {
                    align: 'center',
                },
                Cell: ({ cell }) => (
                    <Stack direction="row" spacing={0.5} sx={{ justifyContent: 'center' }}>
                        <IconButton onClick={() => handleEditClick(cell.row.original.id)}>
                            <ModeEditIcon color="primary" fontSize="small" />
                        </IconButton>
                        <IconButton onClick={() => handleDeleteClick(cell.row.original.id)}>
                            <DeleteIcon color="error" fontSize="small" />
                        </IconButton>
                    </Stack>
                ),
            },
        ],
        [t, setRoomtype, roomtype, handleEditClick, handleDeleteClick],
    );

    const renderedRows = useMemo(() => {
        return roomtype.map((row, index) => {
            return { ...row, index: index + 1 };
        });
    }, [roomtype]);

    const table = useMaterialReactTable({
        columns,
        data: renderedRows,
        initialState: { density: 'compact', pagination: { pageSize: 25 } },
    });

    return (
        <div className={locale === "en" ? "ltr" : "rtl"}>
            {loading && <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
            >
                <CircularProgress color="inherit" />
            </Backdrop>}
            <Stack direction='row' sx={{ justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography sx={{ fontSize: '20px', fontWeight: 'bold', mb: '20px' }}>
                    {t('Users')}
                </Typography>
                <Stack onClick={handleAddClick} direction='row' className='addBtn' >
                    <AddIcon sx={{ mr: 1, fontSize: "14px" }} />
                    <Typography sx={{ fontSize: "12px" }}>{t("Add")}</Typography>
                </Stack>
            </Stack>
            <MaterialReactTable table={table} />

            {/* ================ add dialog ================ */}
            <UserTableAddCrud showAddDialog={showAddDialog} setShowAddDialog={setShowAddDialog} setRoomtype={setRoomtype} setRunUseEffect={setRunUseEffect} />
            {/* ================ add dialog ================ */}

            {/* ================ edit dialog ================ */}
            <UserTableEditCrud showEditDialog={showEditDialog} setShowEditDialog={setShowEditDialog} updatedRows={updatedRows} setUpdatedRows={setUpdatedRows} roomtype={roomtype} setRoomtype={setRoomtype} rowToEdit={rowToEdit} setRunUseEffect={setRunUseEffect} />
            {/* ================ edit dialog ================ */}

            {/* ================ delete dialog ================ */}
            <Dialog
                dir={locale === "en" ? "ltr" : "rtl"}
                // className={locale === "en" ? "ltr" : "rtl"}
                open={showDeleteDialog}
                onClose={handleDeleteClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t('Are you sure you want to delete this item?')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className='dialogDelete' sx={{ padding: "0 0 10px !important", }} id="alert-dialog-description">
                        {t('This item will be permanently deleted.')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleDeleteClose} sx={{ textTransform: 'capitalize' }}>{t('Disagree')}</Button>
                    <Button className='dialogDeleteBtn' variant="contained" color="error" onClick={handleDeleteConfirm} sx={{ textTransform: 'capitalize' }} autoFocus>
                        {t('Agree')}
                    </Button>
                </DialogActions>
            </Dialog>
            {/* ================ delete dialog ================ */}

        </div>
    )
}
