import React from 'react'
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';
import ApexChart from '../component/ApexChart';
import ApexChartLine from '../component/ApexChartLine';

export default function Row2() {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
        <Grid container spacing={2}>
          <Grid xs={12} md={6} lg={4}>
            <Paper elevation={0} sx={{height: '100%' ,padding: '24px', borderRadius: '16px', boxShadow: 'rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px' }}>
              <div style={{ fontSize: '1.25rem', fontWeight: '700' }}>{t('Current Download')}</div>
              <ApexChart/>
            </Paper>
          </Grid>
          <Grid xs={12} md={6} lg={8}>
            <Paper elevation={0} sx={{padding: '24px', borderRadius: '16px', boxShadow: 'rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px' }}>
            <div style={{ fontSize: '1.25rem', fontWeight: '700' }}>{t('Area Installed')}</div>
            <span style={{ fontSize: '0.875rem', marginTop: '4px', fontWeight: '400' }}>{t('(+43%) than last year')}</span>
              <ApexChartLine />
            </Paper>
          </Grid>
        </Grid>
    </div>
  )
}
