import React from 'react'
import TranscationTable from '../component/TranscationTable'
import { Container } from '@mui/material';

export default function Transcation() {
  return (
    <div>
      <Container maxWidth="xl">
        <TranscationTable />
      </Container>
    </div>
  )
}
