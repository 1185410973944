import React from 'react';
import ReactDOM from 'react-dom'; // Correct import
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import global_en from './translations/en/global.json';
import global_ar from './translations/ar/global.json';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SnackBar from './component/SnackBar';
import { ToastContext } from './contexts/ToastContext';
import { useState } from 'react';
import { LocaleContext } from './contexts/LocaleContext';
import { AuthProvider } from './contexts/AuthProvider';
import RequireAuth from './component/RequireAuth';

import Dashboard from './pages/Dashboard';
import Amenities from './pages/Amenities';
import Setting from './pages/Setting';
import Rooms from './pages/Rooms';
import RoomTypes from './pages/RoomTypes';
import Calendar from './pages/Calendar';
import Login from './login/Login';
import Missing from './pages/Missing';
import Booking from './pages/Booking';

import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import Users from './pages/Users';
import Transcation from './pages/Transcation';

i18next.init({
  interpolation: { escapeValue: false }, 
  lng: 'en',
  resources: {
    en: { global: global_en },
    ar: { global: global_ar }
  }
});

const theme = createTheme({
  typography: {
    fontFamily: ['Almarai']
  }
});

// direction right and left
const rtlCache = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});
const ltrCache = createCache({
  key: 'mui',
});

const AppRouter = () => {
  const [locale, setLocale] = useState("en");
  const [openSb, setOpenSb] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');

  // toast
  function showHideToast(message, severity = 'success') {
    setOpenSb(true);
    setMessage(message);
    setSeverity(severity);
    setTimeout(() => {
      setOpenSb(false);
    }, 4000);
  }

  return (
    <CacheProvider value={locale === "ar" ? rtlCache : ltrCache}>
      <I18nextProvider i18n={i18next}>
        <LocaleContext.Provider value={{ locale, setLocale }}>
          <ThemeProvider theme={theme}>
            <ToastContext.Provider value={{ showHideToast }}>
              <SnackBar openSb={openSb} message={message} severity={severity} />
              <BrowserRouter>
                <AuthProvider>
                  <Routes>
                    <Route path="/" element={<App />}>

                      {/* public routes */}
                      <Route path="login" element={<Login />} />

                      {/* we want to protect these routes */}
                      <Route element={<RequireAuth allowedRoles={1} />}>
                        <Route path="/" element={<Dashboard />} />
                        <Route path="amenities" element={<Amenities />} />
                        <Route path="settings" element={<Setting />} />
                        <Route path="rooms" element={<Rooms />} />
                        <Route path="roomTypes" element={<RoomTypes />} />
                        <Route path="calendar" element={<Calendar />} />
                        <Route path="users" element={<Users />} />
                        <Route path="bookings" element={<Booking />} />
                        <Route path="transcation" element={<Transcation />} />
                      </Route>

                      {/* catch all */}
                      <Route path="*" element={<Missing />} />
                    </Route>
                  </Routes>
                </AuthProvider>
              </BrowserRouter>
            </ToastContext.Provider>
          </ThemeProvider>
        </LocaleContext.Provider>
      </I18nextProvider>
    </CacheProvider >
  );
};

ReactDOM.render(
  <AppRouter />,
  document.getElementById('root')
);


reportWebVitals();
