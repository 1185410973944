import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import axios from 'axios';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastContext } from '../../contexts/ToastContext';
import { LocaleContext } from '../../contexts/LocaleContext';
import { useTheme } from '@mui/material/styles';

export default function RoomTypesEditCrud({ showEditDialog, setShowEditDialog, setRoomtype, roomtype, rowToEdit, updatedRows, setUpdatedRows }) {
  const { locale, setLocale } = useContext(LocaleContext);
  const { showHideToast } = useContext(ToastContext);

  const [t] = useTranslation("global");
  const theme = useTheme({
    Typography: {
      fontFamily: [
        'Almarai',
      ].join(','),
    }
  });

  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    setLoading(false);
  };
  const handleOpen = () => {
    setLoading(true);
  };

  //  ================ edit function ================
  function handleEditClose() {
    setShowEditDialog(false);
  }
  function handleEditSubmit(event) {
    event.preventDefault();
    handleOpen();
    const url = `https://panorama.site-pocket.com/api/room-types/${rowToEdit}`;
    const params = {
      name_en: updatedRows.NameEN,
      name_ar: updatedRows.NameAR,
    };

    const tokenString = localStorage.getItem('auth');
    const tokenObject = JSON.parse(tokenString);
    const token = tokenObject.accessToken;

    axios.put(url, params, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(function (response) {
        const updatedRowsArray = roomtype.map((row) => {
          if (row.id === rowToEdit) {
            return {
              ...row,
              name_en: updatedRows.NameEN,
              name_ar: updatedRows.NameAR,
            };
          }
          return row;
        });
        setRoomtype(updatedRowsArray);
        setShowEditDialog(false);
        showHideToast(t("edited successfully"));
        handleClose();
      })
      .catch(function (error) {
        // Handle errors here
        let errorMessage = 'An error occurred while updating the resource. Please try again.';
        if (error.response) {
          if (error.response.status === 400) {
            errorMessage = 'Bad request. Please check your input data and try again.';
          } else if (error.response.status === 401) {
            errorMessage = 'Unauthorized. Please login to perform this action.';
          } else if (error.response.status === 403) {
            errorMessage = 'Forbidden. You do not have permission to perform this action.';
          } else if (error.response.status === 404) {
            errorMessage = 'Resource not found.';
          } else if (error.response.status === 409) {
            errorMessage = 'Conflict. The resource could not be updated due to conflicts.';
          } else if (error.response.status === 500) {
            errorMessage = 'Internal server error. Please try again later.';
          } else {
            errorMessage = 'An unexpected error occurred. Please try again later.';
          }
        }
        handleClose();
        showHideToast(errorMessage, "error");
      });
  }

  //  ================ edit function ================

  return (
    <div>
      {loading && <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>}
      <Dialog
        dir={locale === "en" ? "ltr" : "rtl"}
        className={theme.palette.mode === 'light' ? 'light' : 'dark'}
        open={showEditDialog}
        onClose={handleEditClose}
      >
        <DialogTitle sx={{ pb: 1 }}>{t('Edit')}</DialogTitle>
        <DialogContent sx={{ pt: '10px !important', pb: '0px !important' }}>
          <TextField value={updatedRows.NameEN} onChange={(e) => setUpdatedRows({ ...updatedRows, NameEN: e.target.value })} sx={{ mb: 2 }} fullWidth label={t("Name EN")} type="text" required size="small" />
          <TextField value={updatedRows.NameAR} onChange={(e) => setUpdatedRows({ ...updatedRows, NameAR: e.target.value })} sx={{ mb: 2 }} fullWidth label={t("Name AR")} type="text" required size="small" />
        </DialogContent>
        <DialogActions>
          <Button sx={{ textTransform: 'capitalize' }} variant="contained" color="error" onClick={handleEditClose}>{t('Cancel')}</Button>
          <Button className='dialogDeleteBtn' onClick={handleEditSubmit} sx={{ textTransform: 'capitalize' }} variant="contained" type="submit">{t('Confirm')}</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
