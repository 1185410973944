import { Link } from "react-router-dom"
import { Container } from '@mui/material';
import '../css/missing.css'

const Missing = () => {
    return (
        <Container maxWidth="xl">
            <section class="page_404">
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <div class="four_zero_four_bg">
                        <h1 style={{ textAlign: 'center', marginTop: '-50px' }}>404</h1>
                    </div>

                    <div class="contant_box_404">
                        <h3>
                            Look like you're lost
                        </h3>
                        <p>the page you are looking for not available!</p>
                        <a href="/" class="link_404">Visit Our Homepage</a>
                    </div>
                </div>
            </section>
        </Container>
        // <article style={{ padding: "100px" }}>
        //     <h1>Oops!</h1>
        //     <p>Page Not Found</p>
        //     <div className="flexGrow">
        //         <Link to="/">Visit Our Homepage</Link>
        //     </div>
        // </article>

    )
}

export default Missing