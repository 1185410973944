import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Avatar from '@mui/material/Avatar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';

import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastContext } from '../../contexts/ToastContext';
import { LocaleContext } from '../../contexts/LocaleContext';
import { useTheme } from '@mui/material/styles';

export default function AmenitiesAddCrud({ showAddDialog, setShowAddDialog, setRoomtype }) {
    const { locale, setLocale } = useContext(LocaleContext);
    const { showHideToast } = useContext(ToastContext);

    const [t] = useTranslation("global");
    const theme = useTheme({
        Typography: {
            fontFamily: [
                'Almarai',
            ].join(','),
        }
    });

    const [loading, setLoading] = useState(false);
    const handleClose = () => {
        setLoading(false);
    };
    const handleOpen = () => {
        setLoading(true);
    };

    //  ================ add state ================
    const [addInput, setAddInput] = useState({
        NameEN: '',
        NameAR: '',
        Image: null,
        ImagePreview: null
    })
    //  ================ add state ================


    //  ================ add function ================
    function handleAddClose() {
        setShowAddDialog(false);
        setAddInput({
            ...addInput,
            Image: null,
            ImagePreview: null
        });
    }

    function handleImageChange(event) {
        const imageFile = event.target.files[0];
        setAddInput({
            ...addInput,
            Image: imageFile,
            ImagePreview: URL.createObjectURL(imageFile) // Create object URL for preview
        });
    }

    function handleDialogSubmit(event) {
        event.preventDefault();
        handleOpen();
        const url = 'https://panorama.site-pocket.com/api/amenities';

        let formData = new FormData();
        formData.append('name_en', addInput.NameEN);
        formData.append('name_ar', addInput.NameAR);
        if (addInput.Image !== null) { // Check if an image is selected
            formData.append('image_path', addInput.Image);
        }
        const tokenString = localStorage.getItem('auth');
        const tokenObject = JSON.parse(tokenString);
        const token = tokenObject.accessToken;

        axios.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(function (response) {
                const newRow = {
                    id: response.data.amenity.id,
                    name_en: addInput.NameEN,
                    name_ar: addInput.NameAR,
                    image_path: response.data.amenity.image_path
                };
                setRoomtype((prevRows) => [...prevRows, newRow]); // Update roomtype state with new data
                showHideToast(t("added successfully"));
                setShowAddDialog(false);
                setAddInput({
                    NameEN: "",
                    NameAR: "",
                    Image: null
                });
                handleClose();
            })
            .catch(function (error) {
                let errorMessage = 'An error occurred. Please try again.';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = 'Bad request. Please check your input.';
                    } else if (error.response.status === 401) {
                        errorMessage = 'Unauthorized. Please log in again.';
                    } else if (error.response.status === 403) {
                        errorMessage = 'Forbidden. You do not have permission to perform this action.';
                    } else if (error.response.status === 404) {
                        errorMessage = 'Resource not found.';
                    } else if (error.response.status === 500) {
                        errorMessage = 'Internal server error. Please try again later.';
                    } else if (error.response.data.message.includes('The name en field must be a string')) {
                        errorMessage = 'you need to enter Name EN.';
                    } else if (error.response.data.message.includes('The name ar field must be a string')) {
                        errorMessage = 'you need to enter Name AR.';
                    } else {
                        errorMessage = 'An unexpected error occurred. Please try again later.';
                    }
                }
                handleClose();
                showHideToast(errorMessage, "error");
            });
    }
    //  ================ add function ================

    return (
        <div>
            {loading && <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
            >
                <CircularProgress color="inherit" />
            </Backdrop>}
            <Dialog
                className={locale === "en" ? "ltr" : "rtl"}
                dir={locale === "en" ? "ltr" : "rtl"}
                open={showAddDialog}
                onClose={handleAddClose}
            >
                <DialogTitle sx={{ pb: 0 }}>{t('Add')}</DialogTitle>
                <DialogContent sx={{ pt: '10px !important' }}>
                    <TextField
                        size="small"
                        type='text'
                        sx={{ mb: 2, }}
                        required
                        fullWidth
                        label={t("Name EN")}
                        name="NameEN"
                        value={addInput.NameEN}
                        onChange={(e) => {
                            setAddInput({ ...addInput, NameEN: e.target.value })
                        }}
                    />
                    <TextField
                        size="small"
                        sx={{ mb: 2 }}
                        type='text'
                        required
                        fullWidth
                        name='NameAR'
                        label={t("Name AR")}
                        value={addInput.NameAR}
                        onChange={(e) => {
                            setAddInput({ ...addInput, NameAR: e.target.value })
                        }}
                    />
                    <input style={{ display: 'none' }} type="file" name="Image" onChange={handleImageChange} />
                    <Button className='amenityAddbtn' variant="outlined" component="label" sx={{ textTransform: 'capitalize' }}>
                        {t('Choose Image')}
                        <input type="file" accept="image/*" style={{ display: 'none' }} onChange={handleImageChange} />
                    </Button>
                    {addInput.ImagePreview && (
                        <div style={{ position: 'relative', marginRight: '10px', marginBottom: '10px', width: '80px', height: '80px' }}>
                            <Avatar variant="square" sx={{ width: '80px', height: '80px' }} alt="Preview" src={addInput.ImagePreview} />
                            {/* <div style={{ color: 'white', backgroundColor: '#00000080', cursor: 'pointer', position: 'absolute', top: '5px', right: '5px', borderRadius: '50%', width: '26px', height: '26px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={handleRemoveImage}>x</div> */}

                        </div>
                    )}
                </DialogContent>

                <DialogActions>
                    <Button sx={{ textTransform: 'capitalize' }} variant="contained" color="error" onClick={handleAddClose}>{t("Cancel")}</Button>
                    <Button
                        sx={{ textTransform: 'capitalize' }}
                        variant="contained"
                        disabled={!addInput.NameEN || !addInput.NameAR}
                        onClick={handleDialogSubmit} type="submit">{t("Submit")}</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
