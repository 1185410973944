import { Button, Stack } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import AddIcon from '@mui/icons-material/Add';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import axios from 'axios';
import { useMemo, useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastContext } from '../contexts/ToastContext';
import { LocaleContext } from '../contexts/LocaleContext';
import { useTheme } from '@mui/material/styles';
import RoomTypesAddCrud from './CRUD/RoomTypesAddCrud';
import RoomTypesEditCrud from './CRUD/RoomTypesEditCrud';

export default function RoomTypesTable() {
  const [roomtype, setRoomtype] = useState([]);
  const { locale, setLocale } = useContext(LocaleContext);
  const { showHideToast } = useContext(ToastContext);

  const [t] = useTranslation("global");
  const theme = useTheme({
    Typography: {
      fontFamily: [
        'Almarai',
      ].join(','),
    }
  });

  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    setLoading(false);
  };
  const handleOpen = () => {
    setLoading(true);
  };

  // get all data
  useEffect(() => {
    handleOpen();
    axios.get('https://panorama.site-pocket.com/api/room-types',)
      .then(function (response) {
        setRoomtype(response.data.roomTypes);
        handleClose();
      })
      .catch(function (error) {
        // Handle errors here
        let errorMessage = 'An error occurred while fetching data. Please try again.';
        if (error.response) {
          if (error.response.status === 404) {
            errorMessage = 'Resource not found.';
          } else if (error.response.status === 500) {
            errorMessage = 'Internal server error. Please try again later.';
          } else {
            errorMessage = 'An unexpected error occurred. Please try again later.';
          }
        } else if (error.request) {
          errorMessage = 'Request made but no response received. Please check your internet connection.';
        } else {
          errorMessage = 'An unexpected error occurred. Please try again later.';
        }
        console.error("Error fetching data:", error);
        showHideToast(errorMessage, "error");
        handleClose();
      });
  }, []);


  //  ====== open && close add state ========
  const [showAddDialog, setShowAddDialog] = useState(false);
  //  ====== open && close add state ========

  //  ====== open && close edit state ========
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [updatedRows, setUpdatedRows] = useState({
    NameEN: '',
    NameAR: '',
  });
  //  ====== open && close edit state ========

  //  ====== open && close delete state ========
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);
  //  ====== open && close delete state ========

  //  ================ add function ================
  function handleAddClick() {
    setShowAddDialog(true);
  }
  //  ================ edit function ================
  function handleEditClick(id) {
    const row = roomtype.find((row) => row.id === id);
    setUpdatedRows({
      NameEN: row.name_en,
      NameAR: row.name_ar,
    });
    setRowToEdit(id);
    setShowEditDialog(true);
  }
  //  ================ edit function ================

  //  ================ delete function ================
  function handleDeleteClick(id) {
    setRowToDelete(id);
    setShowDeleteDialog(true);
  }

  function handleDeleteClose() {
    setShowDeleteDialog(false);
  }

  function handleDeleteConfirm() {
    handleOpen();
    const url = `https://panorama.site-pocket.com/api/room-types/${rowToDelete}`;

    const tokenString = localStorage.getItem('auth');
    const tokenObject = JSON.parse(tokenString);
    const token = tokenObject.accessToken;

    axios.delete(url, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(function (response) {
        const updatedRows = roomtype.filter((row) => row.id !== rowToDelete);
        setRoomtype(updatedRows);
        setShowDeleteDialog(false);
        showHideToast(t("deleted successfully"));
        handleClose();
      })
      .catch(function (error) {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response) {
          if (error.response.status === 403) {
            errorMessage = 'Forbidden. You do not have permission to perform this action.';
          } else if (error.response.status === 404) {
            errorMessage = 'Resource not found.';
          } else if (error.response.status === 500) {
            errorMessage = 'Internal server error. Please try again later.';
          } else {
            errorMessage = 'An unexpected error occurred. Please try again later.';
          }
        }
        handleClose();
        showHideToast(errorMessage, "error");
      });
  }

  //  ================ delete function ================
  const columns = useMemo(
    () => [
      {
        accessorKey: 'index',
        header: '#',
        size: 80,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'name_en',
        header: (t('Name EN')),
        size: 150,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'name_ar',
        header: (t('Name AR')),
        size: 80,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'Action',
        header: (t('Actions')),
        size: 150,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
        Cell: ({ cell }) => (
          <Stack direction="row" spacing={0.5} sx={{ justifyContent: 'center' }}>
            <IconButton onClick={() => handleEditClick(cell.row.original.id)}>
              <ModeEditIcon color="primary" fontSize="small" />
            </IconButton>
            <IconButton onClick={() => handleDeleteClick(cell.row.original.id)}>
              <DeleteIcon color="error" fontSize="small" />
            </IconButton>
          </Stack>
        ),
      },
    ],
    [t, setRoomtype, roomtype, handleEditClick, handleDeleteClick],
  );

  const renderedRows = useMemo(() => {
    return roomtype.map((row, index) => {
      return { ...row, index: index + 1 };
    });
  }, [roomtype]);

  const table = useMaterialReactTable({
    columns,
    data: renderedRows,
    initialState: { density: 'compact' },
  });

  return (
    <div className={locale === "en" ? "ltr" : "rtl"}>
      {loading && <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>}
      <Stack direction='row' sx={{ justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography sx={{ fontSize: '20px', fontWeight: 'bold', mb: '20px' }}>
          {t('Room Types')}
        </Typography>
        <Stack onClick={handleAddClick} direction='row' className='addBtn' >
          <AddIcon sx={{ mr: 1, fontSize: "14px" }} />
          <Typography sx={{ fontSize: "12px" }}>{t("Add")}</Typography>
        </Stack>
      </Stack>
      <MaterialReactTable table={table} />

      {/* ================ add dialog ================ */}
      <RoomTypesAddCrud setShowAddDialog={setShowAddDialog} showAddDialog={showAddDialog} setRoomtype={setRoomtype} />
      {/* ================ add dialog ================ */}

      {/* ================ edit dialog ================ */}
      <RoomTypesEditCrud updatedRows={updatedRows} setUpdatedRows={setUpdatedRows} setShowEditDialog={setShowEditDialog} showEditDialog={showEditDialog} setRoomtype={setRoomtype} roomtype={roomtype} rowToEdit={rowToEdit} />
      {/* ================ edit dialog ================ */}

      {/* ================ delete dialog ================ */}
      <Dialog
        dir={locale === "en" ? "ltr" : "rtl"}
        // className={locale === "en" ? "ltr" : "rtl"}
        open={showDeleteDialog}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('Are you sure you want to delete this item?')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText className='dialogDelete' sx={{ padding: "0 0 10px !important", }} id="alert-dialog-description">
            {t('This item will be permanently deleted.')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleDeleteClose} sx={{ textTransform: 'capitalize' }}>{t('Disagree')}</Button>
          <Button className='dialogDeleteBtn' variant="contained" color="error" onClick={handleDeleteConfirm} sx={{ textTransform: 'capitalize' }} autoFocus>
            {t('Agree')}
          </Button>
        </DialogActions>
      </Dialog>
      {/* ================ delete dialog ================ */}

    </div>
  )
}
