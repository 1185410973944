import { Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import RoomsTable from '../component/RoomsTable';

export default function Rooms() {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <Container maxWidth="xl">
        <RoomsTable />
      </Container>
    </div>
  )
}
