import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import { Container, Stack } from '@mui/material';
import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';
import { LocaleContext } from '../contexts/LocaleContext';

export default function Row1() {
    const [t, i18n] = useTranslation('global');
    const { locale, setLocale } = useContext(LocaleContext);
    return (
        <div>
                <Grid container spacing={2}>
                    <Grid xs={12} sm={6} md={4} lg={4} sx={{padding: '12px'}}>
                        <Paper elevation={0} sx={{ display: 'flex', justifyContent: 'space-between', padding: '24px', borderRadius: '16px', boxShadow: 'rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px' }}>
                            <Stack direction={"column"} spacing={2}>
                                <div style={{ fontSize: '0.875rem', fontWeight: '600' }}>{t('Total Active Users')}</div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Icon style={{ marginRight: '8px', width: '24px', height: '24px', color: '#22c55e' }} icon="solar:double-alt-arrow-up-bold-duotone" />
                                    <div style={{ fontSize: '0.875rem', fontWeight: '600' }}>+2.6%</div>
                                </div>
                                <Box sx={{ fontSize: { xs: '1.625rem;', md: '1.875rem', lg: '2rem' }, fontWeight: '700' }}>18,765</Box>
                            </Stack>
                            <Stack direction={"column"} sx={{ alignItems: 'center', justifyContent: 'center' }}>
                                <Icon style={{ width: '50px', height: '50px', color: '#22c55e' }} icon="solar:double-alt-arrow-up-bold-duotone" />
                            </Stack>
                        </Paper>
                    </Grid>
                    <Grid xs={12} sm={6} md={4} lg={4} sx={{padding: '12px'}}>
                        <Paper elevation={0} sx={{ display: 'flex', justifyContent: 'space-between', padding: '24px', borderRadius: '16px', boxShadow: 'rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px' }}>
                            <Stack direction={"column"} spacing={2}>
                                <div style={{ fontSize: '0.875rem', fontWeight: '600' }}>{t('Total Active Users')}</div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Icon style={{ marginRight: '8px', width: '24px', height: '24px', color: '#22c55e' }} icon="solar:double-alt-arrow-up-bold-duotone" />
                                    <div style={{ fontSize: '0.875rem', fontWeight: '600' }}>+2.6%</div>
                                </div>
                                <Box sx={{ fontSize: { xs: '1.625rem;', md: '1.875rem', lg: '2rem' }, fontWeight: '700' }}>18,765</Box>
                            </Stack>
                            <Stack direction={"column"} sx={{ alignItems: 'center', justifyContent: 'center' }}>
                                <Icon style={{ width: '50px', height: '50px', color: '#22c55e' }} icon="solar:double-alt-arrow-up-bold-duotone" />
                            </Stack>
                        </Paper>
                    </Grid>
                    <Grid xs={12} sm={6} md={4} lg={4} sx={{padding: '12px'}}>
                        <Paper elevation={0} sx={{ display: 'flex', justifyContent: 'space-between', padding: '24px', borderRadius: '16px', boxShadow: 'rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px' }}>
                            <Stack direction={"column"} spacing={2}>
                                <div style={{ fontSize: '0.875rem', fontWeight: '600' }}>{t('Total Active Users')}</div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Icon style={{ marginRight: '8px', width: '24px', height: '24px', color: '#ff5630' }} icon="solar:double-alt-arrow-down-bold-duotone"  />
                                    <div style={{ fontSize: '0.875rem', fontWeight: '600' }}>+2.6%</div>
                                </div>
                                <Box sx={{ fontSize: { xs: '1.625rem;', md: '1.875rem', lg: '2rem' }, fontWeight: '700' }}>18,765</Box>
                            </Stack>
                            <Stack direction={"column"} sx={{ alignItems: 'center', justifyContent: 'center' }}>
                                <Icon style={{ width: '50px', height: '50px', color: '#ff5630' }} icon="solar:double-alt-arrow-down-bold-duotone"  />
                            </Stack>
                        </Paper>
                    </Grid>
                </Grid>
        </div>
    )
}
