import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';

import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastContext } from '../../contexts/ToastContext';
import { LocaleContext } from '../../contexts/LocaleContext';
import { useTheme } from '@mui/material/styles';

export default function UserTableEditCrud({ showEditDialog, setShowEditDialog, updatedRows, setUpdatedRows, roomtype, setRoomtype, rowToEdit, setRunUseEffect }) {
    const { showHideToast } = useContext(ToastContext);
    const { locale, setLocale } = useContext(LocaleContext);
    const [t] = useTranslation("global");
    const theme = useTheme({
        Typography: {
            fontFamily: [
                'Almarai',
            ].join(','),
        }
    });

    const [loading, setLoading] = useState(false);
    const handleClose = () => {
        setLoading(false);
    };
    const handleOpen = () => {
        setLoading(true);
    };
    //  ================ edit function ================
    function handleEditClose() {
        setShowEditDialog(false);
    }
    function handleEditSubmit(event) {
        event.preventDefault();
        handleOpen();
        const url = `https://panorama.site-pocket.com/api/users/${rowToEdit}`;
        const params = {
            name: updatedRows.Name,
            email: updatedRows.Email,
            phone: updatedRows.Phone,
            password: updatedRows.Password,
            nationality: updatedRows.Nationality,
            email_verified: Boolean(updatedRows.Verified),
            is_admin: updatedRows.Admin,
        };
        const tokenString = localStorage.getItem('auth');
        const tokenObject = JSON.parse(tokenString);
        const token = tokenObject.accessToken;

        axios.put(url, params, {
            headers: { Authorization: `Bearer ${token}` },
        })
            .then(function (response) {
                const updatedRowsArray = roomtype.map((row) => {
                    if (row.id === rowToEdit) {
                        return {
                            ...row,
                            name: updatedRows.Name,
                            email: updatedRows.Email,
                            phone: updatedRows.Phone,
                            password: updatedRows.Password,
                            nationality: updatedRows.Nationality,
                            email_verified: updatedRows.Verified,
                            is_admin: updatedRows.Admin,
                        };
                    }
                    return row;
                });
                setRoomtype(updatedRowsArray);
                setShowEditDialog(false);
                showHideToast(t("edited successfully"));
                setRunUseEffect((prev) => prev + 1);
                handleClose();
            })
            .catch(function (error) {
                let errorMessage = 'An error occurred. Please try again.';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = 'Bad request. Please check your input.';
                    } else if (error.response.status === 401) {
                        errorMessage = 'Unauthorized. Please log in again.';
                    } else if (error.response.status === 403) {
                        errorMessage = 'Forbidden. You do not have permission to perform this action.';
                    } else if (error.response.status === 404) {
                        errorMessage = 'Resource not found.';
                    } else if (error.response.status === 500) {
                        errorMessage = 'Internal server error. Please try again later.';
                    } else if (error.response.data.message.includes('The email field must be a valid email')) {
                        errorMessage = 'you need to enter valid email.';
                    } else if (error.response.data.message.includes('The password field must be at least 6 characters')) {
                        errorMessage = 'The password field must be at least 6 characters.';
                    } else {
                        errorMessage = 'An unexpected error occurred. Please try again later.';
                    }
                }
                handleClose();
                showHideToast(errorMessage, "error");
            });
    }
    //  ================ edit function ================

    return (
        <div>
            {loading && <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
            >
                <CircularProgress color="inherit" />
            </Backdrop>}
            <Dialog
                dir={locale === "en" ? "ltr" : "rtl"}
                // className={locale === "en" ? "ltr" : "rtl"}
                open={showEditDialog}
                onClose={handleEditClose}
            >
                <DialogTitle sx={{ pb: 1 }}>{t('Edit')}</DialogTitle>
                <DialogContent sx={{ pt: '10px !important', pb: '0px !important' }}>
                    <TextField value={updatedRows.Name} onChange={(e) => setUpdatedRows({ ...updatedRows, Name: e.target.value })} sx={{ mb: 2 }} fullWidth label={t("Name")} type="text" size="small" />
                    <TextField value={updatedRows.Email} onChange={(e) => setUpdatedRows({ ...updatedRows, Email: e.target.value })} sx={{ mb: 2 }} fullWidth label={t("Email")} type="email" size="small" />
                    <TextField value={updatedRows.Phone} onChange={(e) => setUpdatedRows({ ...updatedRows, Phone: e.target.value })} sx={{ mb: 2 }} fullWidth label={t("Phone")} type="text" size="small" />
                    <TextField value={updatedRows.Password} onChange={(e) => setUpdatedRows({ ...updatedRows, Password: e.target.value })} sx={{ mb: 2 }} fullWidth label={t("Password")} type="password" size="small" />
                    <TextField value={updatedRows.Nationality} onChange={(e) => setUpdatedRows({ ...updatedRows, Nationality: e.target.value })} sx={{ mb: 2 }} fullWidth label={t("Nationality Id")} type="text" size="small" />
                    <span>{t('Email Verified')}</span>
                    <Switch
                        checked={Boolean(updatedRows.Verified)} // Make sure updatedRows.Verified is a boolean
                        onChange={(e) => setUpdatedRows({ ...updatedRows, Verified: e.target.checked })}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <span>{t('Is Admin')}</span>
                    <Switch
                        checked={Boolean(updatedRows.Admin)}
                        onChange={(e) => setUpdatedRows({ ...updatedRows, Admin: e.target.checked })}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button sx={{ textTransform: 'capitalize' }} variant="contained" color="error" onClick={handleEditClose}>{t('Cancel')}</Button>
                    <Button className='dialogDeleteBtn' onClick={handleEditSubmit} sx={{ textTransform: 'capitalize' }} variant="contained" type="submit">{t('Confirm')}</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
