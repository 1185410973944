import { Box, Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Avatar from '@mui/material/Avatar';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import axios from 'axios';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastContext } from '../../contexts/ToastContext';
import { LocaleContext } from '../../contexts/LocaleContext';
import { useTheme } from '@mui/material/styles';

export default function RoomsTableAddCrud({ showAddDialog, setShowAddDialog, selectedAmenities, setSelectedAmenities, setRoomtype, setRunUseEffect, roomTypes, amenities, handleCheckboxChange, handleEditImageChange, imagePreviews, handleEditRemoveImage, selectedImages, setSelectedImages }) {

    const { locale, setLocale } = useContext(LocaleContext);
    const { showHideToast } = useContext(ToastContext);
    const [t] = useTranslation("global");
    const theme = useTheme({
        Typography: {
            fontFamily: [
                'Almarai',
            ].join(','),
        }
    });

    const [loading, setLoading] = useState(false);
    const handleClose = () => {
        setLoading(false);
    };
    const handleOpen = () => {
        setLoading(true);
    };

    //  ================ add state ================
    const [addInput, setAddInput] = useState({
        RoomNo: '',
        Price: '',
        Status: '',
        RoomTypeId: '',
    })
    //  ================ add state ================

    //  ================ add function ================
    function handleAddClose() {
        setShowAddDialog(false);
    }

    function handleDialogSubmit(event) {
        event.preventDefault();
        handleOpen();
        const url = 'https://panorama.site-pocket.com/api/rooms';
        let formData = new FormData();
        formData.append('room_number', addInput.RoomNo);
        formData.append('price', addInput.Price);
        formData.append('status', addInput.Status);
        formData.append('room_type_id', addInput.RoomTypeId);
        selectedAmenities.forEach(amenityId => {
            formData.append('amenity_ids[]', amenityId);
        });
        for (let i = 0; i < selectedImages.length; i++) {
            formData.append(`images[]`, selectedImages[i]);
        }
        const tokenString = localStorage.getItem('auth');
        const tokenObject = JSON.parse(tokenString);
        const token = tokenObject.accessToken;

        axios.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(function (response) {
                const newRow = {
                    id: response.data.room.id,
                    price: addInput.Price,
                    room_number: addInput.RoomNo,
                    status: addInput.Status,
                    room_type_id: addInput.RoomTypeId,
                    amenities: response.data.room.amenities,
                    image_path: response.data.room.image_path.split(','),
                };

                setRoomtype((prevRows) => [...prevRows, newRow]); // Update roomtype state with new data
                showHideToast(t("added successfully"));
                setShowAddDialog(false);
                setAddInput({
                    Price: "",
                    RoomNo: "",
                    Status: "",
                    RoomTypeId: "",
                });
                setSelectedAmenities([]);
                setSelectedImages([]);
                setRunUseEffect((prev) => prev + 1);
                handleClose();
            })
            .catch(function (error) {
                let errorMessage = 'An error occurred. Please try again.';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = 'Bad request. Please check your input.';
                    } else if (error.response.status === 401) {
                        errorMessage = 'Unauthorized. Please log in again.';
                    } else if (error.response.status === 403) {
                        errorMessage = 'Forbidden. You do not have permission to perform this action.';
                    } else if (error.response.status === 404) {
                        errorMessage = 'Resource not found.';
                    } else if (error.response.status === 500) {
                        errorMessage = 'Internal server error. Please try again later.';
                    } else if (error.response.data.message.includes('The price field must be a number')) {
                        errorMessage = 'The price field must be a number.';
                    } else if (error.response.data.message.includes('The room number field must be a string')) {
                        errorMessage = 'The room number field must be a string.';
                    } else {
                        errorMessage = 'An unexpected error occurred. Please try again later.';
                    }
                }
                handleClose();
                showHideToast(errorMessage, "error");
            });
    }
    //  ================ add function ================

    return (
        <div>
            {loading && <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
            >
                <CircularProgress color="inherit" />
            </Backdrop>}
            <Dialog
                className={theme.palette.mode === 'light' ? 'light' : 'dark'}
                dir={locale === "en" ? "ltr" : "rtl"}
                open={showAddDialog}
                onClose={handleAddClose}
            >
                <DialogTitle sx={{ pb: 0, fontWeight: 'bold' }}>{t('Add')}</DialogTitle>
                <DialogContent sx={{ pt: '10px !important' }}>
                    <TextField
                        required
                        size="small"
                        type='text'
                        sx={{ mb: 2, }}
                        fullWidth
                        label={t("Price")}
                        name="Price"
                        value={addInput.Price}
                        onChange={(e) => {
                            setAddInput({ ...addInput, Price: e.target.value })
                        }}
                    />
                    <TextField
                        required
                        size="small"
                        sx={{ mb: 2 }}
                        type='text'
                        fullWidth
                        name='RoomNo'
                        label={t("Room No.")}
                        value={addInput.RoomNo}
                        onChange={(e) => {
                            setAddInput({ ...addInput, RoomNo: e.target.value })
                        }}
                    />
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel>{t('Status')}</InputLabel>
                        <Select
                            size="small"
                            value={addInput.Status}
                            onChange={(e) => {
                                setAddInput({ ...addInput, Status: e.target.value })
                            }}
                            name="Status"
                            required
                        >
                            <MenuItem value="available">available</MenuItem>
                            <MenuItem value="unavailable">unavailable</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel>{t('Room Type')}</InputLabel>
                        <Select
                            size="small"
                            value={addInput.RoomTypeId}
                            onChange={(e) => setAddInput({ ...addInput, RoomTypeId: e.target.value })}
                            name="RoomTypeId"
                            required
                        >
                            {roomTypes.map(roomType => (
                                <MenuItem key={roomType.id} value={roomType.id}>{locale === "en" ? roomType.name_en : roomType.name_ar}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <div className="group">
                        <h2 style={{ margin: "0 0 10px 0", fontWeight: 'normal', fontSize: '18px' }}>{t('Amenities')}</h2>
                        <div className="checkbox-group" >
                            {amenities.map(amenity => (
                                <div className='list' key={amenity.id}>
                                    <input
                                        name='amenities'
                                        type="checkbox"
                                        id={`amenity-${amenity.id}`}
                                        onChange={(e) => handleCheckboxChange(e, amenity.id)}
                                        className="checkbox"
                                    />
                                    <label htmlFor={`amenity-${amenity.id}`} className="item">
                                        <img style={{ width: '40px', maxHeight: '100px' }} src={amenity.image_path} alt={amenity.name_en} className='logo-type' />
                                        <div style={{ fontSize: '14px' }}>{locale === "en" ? amenity.name_en : amenity.name_ar}</div>
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>

                    <input
                        type="file"
                        style={{ display: 'none' }}
                        accept="image/*"
                        multiple
                        onChange={handleEditImageChange}
                    />
                    <Button className='roomsbtn' variant="outlined" component="label" sx={{ textTransform: 'capitalize' }}>
                        {t('Choose Image')}
                        <input multiple type="file" accept="image/*" style={{ display: 'none' }} onChange={handleEditImageChange} />
                    </Button>
                    {/* Display image previews */}
                    <Box display="flex" flexDirection="row" flexWrap="wrap">
                        {imagePreviews.map((preview, index) => (
                            <Box key={index} position="relative" m={1}>
                                <Avatar
                                    alt={`Image ${index + 1}`}
                                    src={preview}
                                    sx={{ width: 100, height: 100 }}
                                />
                                <IconButton
                                    onClick={() => handleEditRemoveImage(index)}
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        right: 0,
                                        color: 'white',
                                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                        ))}
                    </Box>

                </DialogContent>

                <DialogActions>
                    <Button sx={{ textTransform: 'capitalize' }} variant="contained" color="error" onClick={handleAddClose}>{t("Cancel")}</Button>
                    <Button
                        sx={{ textTransform: 'capitalize' }}
                        variant="contained"
                        disabled={!addInput.Price || !addInput.RoomNo || !addInput.Status || !addInput.RoomTypeId}
                        onClick={handleDialogSubmit} type="submit">{t("Submit")}</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
