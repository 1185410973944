import React, { useState, useEffect, useContext } from 'react';
import { LocaleContext } from '../contexts/LocaleContext';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { useTranslation } from 'react-i18next';
import { Container } from '@mui/material';
import { red, green, orange } from '@mui/material/colors';
import '../css/calendar.css';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import axios from 'axios';
import arLocale from '@fullcalendar/core/locales/ar';
import { useTheme } from '@mui/material/styles';
import { ToastContext } from '../contexts/ToastContext';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const Calendar = () => {
    const [rooms, setRooms] = useState([]);
    const [events, setEvents] = useState([]);
    const [users, setUser] = useState([]);
    const [filteredEvents, setFilteredEvents] = useState([]);
    const [selectedRoom, setSelectedRoom] = useState('');
    const [showTitle, setShowTitle] = useState(true);
    const [t, i18n] = useTranslation("global");
    const { locale, setLocale } = useContext(LocaleContext);
    const [eventDetails, setEventDetails] = useState(null);
    const { showHideToast } = useContext(ToastContext);
    const theme = useTheme({});

    const [loading, setLoading] = useState(false);
    const handleClose = () => {
        setLoading(false);
    };
    const handleOpen = () => {
        setLoading(true);
    };


    //  ====== open && close view state ========
    const [showViewDialog, setShowViewDialog] = useState(false);
    //  ====== open && close view state ========

    useEffect(() => {
        handleOpen();
        const tokenString = localStorage.getItem('auth');
        const tokenObject = JSON.parse(tokenString);
        const token = tokenObject.accessToken;


        const fetchRooms = fetch('https://panorama.site-pocket.com/api/rooms', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(response => response.json());

        // Fetch users from API
        const fetchUsers = axios.get('https://panorama.site-pocket.com/api/users', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(response => response.data.users);

        // Fetch bookings from API
        const fetchBookings = fetch('https://panorama.site-pocket.com/api/bookings', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(response => response.json());

        // Wait for all API calls to resolve
        Promise.all([fetchRooms, fetchUsers, fetchBookings])
            .then(([roomsData, usersData, bookingsData]) => {
                // Process data and update state
                const rooms = roomsData.rooms;
                setRooms(rooms);
                setUser(usersData);

                const bookings = bookingsData.bookings;
                const enrichedBookings = bookings.map(booking => {
                    const user = usersData.find(user => user.id === booking.user_id);
                    const userEmail = user ? user.email : '';
                    const userNationality = user ? user.nationality : '';
                    const userPhone = user ? user.phone : '';

                    return {
                        ...booking,
                        userEmail,
                        userNationality,
                        userPhone
                    };
                });
                // Prepare events array for FullCalendar
                const events = enrichedBookings.flatMap(booking => {
                    return booking.rooms.map(room => {
                        // Determine background color based on booking status
                        let backgroundColor, borderColor, textColor;
                        switch (booking.status) {
                            case 'pending':
                                backgroundColor = orange[100];
                                borderColor = orange[300];
                                textColor = orange[700];
                                break;
                            case 'confirmed':
                                backgroundColor = green[100];
                                borderColor = green[300];
                                textColor = green[700];
                                break;
                            case 'cancelled':
                                backgroundColor = red[100];
                                borderColor = red[300];
                                textColor = red[700];
                                break;
                            default:
                                backgroundColor = 'transparent';
                                borderColor = '#000000';
                                textColor = '#000000';
                        }

                        return {
                            title: `Booking: ${booking.booking_number}\nRoom ${room.room.room_number}\nUser: ${booking.user.name}`,
                            start: room.check_in_date,
                            end: room.check_out_date,
                            roomNumber: room.room.room_number,
                            bookingId: booking.id,
                            bookingNumber: booking.booking_number,
                            checkInDate: room.check_in_date,
                            checkOutDate: room.check_out_date,
                            userId: booking.user.id,
                            userName: booking.user.name,
                            userEmail: booking.userEmail,
                            userNationality: booking.userNationality,
                            userPhone: booking.userPhone,
                            backgroundColor: backgroundColor,
                            borderColor: borderColor, // Set border color
                            textColor: textColor // Set text color
                        };
                    });
                });

                setEvents(events);
                setFilteredEvents(events);
                handleClose();
            })
            .catch(function (error) {
                let errorMessage = 'An error occurred. Please try again.';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = 'Bad request. Please check your input.';
                    } else if (error.response.status === 401) {
                        errorMessage = 'Unauthorized. Please log in again.';
                    } else if (error.response.status === 403) {
                        errorMessage = 'Forbidden. You do not have permission to perform this action.';
                    } else if (error.response.status === 404) {
                        errorMessage = 'Resource not found.';
                    } else if (error.response.status === 500) {
                        errorMessage = 'Internal server error. Please try again later.';
                    } else {
                        errorMessage = 'An unexpected error occurred. Please try again later.';
                    }
                }
                handleClose();
                showHideToast(errorMessage, "error");
            });
    }, []);

    const handleRoomFilterChange = event => {
        const selectedRoom = event.target.value;
        setSelectedRoom(selectedRoom);

        if (selectedRoom) {
            const filteredEvents = events.filter(event => event.roomNumber === selectedRoom);
            setFilteredEvents(filteredEvents);
        } else {
            setFilteredEvents(events);
        }
    };

    const handleEventClick = info => {
        const clickedEventDetails = info.event.extendedProps;
        setEventDetails(clickedEventDetails);
        setShowViewDialog(true);
    };

    //  ================ view function ================
    function handleViewClose() {
        setShowViewDialog(false);
    }
    //  ================ view function ================

    return (
        <div dir={locale === 'en' ? 'ltr' : 'rtl'}>
            {loading && <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
            >
                <CircularProgress color="inherit" />
            </Backdrop>}
            <Container maxWidth="xl">
                <div>
                    <label style={{ fontWeight: 'bold' }} htmlFor="roomFilter">{t('Filter by Room')}:</label>
                    <select id="roomFilter" value={selectedRoom} onChange={handleRoomFilterChange} style={{ marginBottom: '10px', height: '40px', backgroundColor: theme.palette.mode === 'light' ? 'white' : '#2c3e50', color: theme.palette.mode === 'light' ? 'black' : 'white' }}>
                        <option style={{ backgroundColor: theme.palette.mode === 'light' ? 'white' : '#2c3e50', color: theme.palette.mode === 'light' ? 'black' : 'white' }} value="">{t('All Rooms')}</option>
                        {rooms.map(room => (
                            <option style={{ backgroundColor: theme.palette.mode === 'light' ? 'white' : '#2c3e50', color: theme.palette.mode === 'light' ? 'black' : 'white' }} key={room.room_number} value={room.room_number}>{t('Room')} {room.room_number}</option>
                        ))}
                    </select>
                </div>
                <div id="calendar">
                    <FullCalendar
                        height="80vh"
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                        initialView="dayGridMonth"
                        events={filteredEvents}
                        eventClick={handleEventClick} // Handle event click
                        headerToolbar={{
                            start: showTitle ? 'title' : '',
                            center: t('dayGridMonth,timeGridWeek,timeGridDay'),
                            end: t('today prev,next')
                        }}
                        buttonText={{ today: t('today'), month: t('month'), week: t('week'), day: t('day') }}
                        locales={[arLocale]} // Pass locale setting
                        locale={locale} // Set locale
                    />
                </div>
                {/* ================ view dialog ================ */}
                <Dialog
                    dir={locale === "en" ? "ltr" : "rtl"}
                    open={showViewDialog}
                    onClose={handleViewClose}
                    sx={{ width: "600px", margin: "0 auto" }}
                >
                    <DialogTitle id="alert-dialog-title" sx={{ fontSize: "20px", fontWeight: "bold", position: "relative" }}>
                        {t("Booking Details")}
                    </DialogTitle>
                    <DialogContent sx={{ width: "500px !important" }} className='viewSpan'>
                        {eventDetails && (
                            <>
                                <Typography gutterBottom>
                                    <span style={{ fontWeight: "bold" }}>{t('Booking Number')}: </span>
                                    {eventDetails.bookingNumber}
                                </Typography>
                                <Typography gutterBottom>
                                    <span style={{ fontWeight: "bold" }}>{t('User')}: </span>
                                    {eventDetails.userName}
                                </Typography>
                                <Typography gutterBottom>
                                    <span style={{ fontWeight: "bold" }}>{t('Check-in')}: </span>
                                    {eventDetails.checkInDate}
                                </Typography>
                                <Typography gutterBottom>
                                    <span style={{ fontWeight: "bold" }}>{t('Check-out')}: </span>
                                    {eventDetails.checkOutDate}
                                </Typography>
                                <Typography gutterBottom>
                                    <span style={{ fontWeight: "bold" }}>{t('Email')}: </span>
                                    {eventDetails.userEmail}
                                </Typography>
                                <Typography gutterBottom>
                                    <span style={{ fontWeight: "bold" }}>{t('Phone')}: </span>
                                    {eventDetails.userPhone}
                                </Typography>
                                <Typography gutterBottom>
                                    <span style={{ fontWeight: "bold" }}>{t('Nationality Id')}: </span>
                                    {eventDetails.userNationality}
                                </Typography>
                            </>
                        )}
                        <IconButton className='btnClose' color="error" onClick={handleViewClose} sx={{ position: "absolute", top: '10px', right: '10px' }}>
                            <CloseIcon />
                        </IconButton>
                    </DialogContent>
                </Dialog>
                {/* ================ view dialog ================ */}
            </Container>
        </div>
    );
};

export default Calendar;
