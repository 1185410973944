import { useRef, useEffect, useState, useContext } from 'react'
import useAuth from '../hooks/useAuth';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import TextField from '@mui/material/TextField';

import { ToastContext } from '../contexts/ToastContext';
import { LocaleContext } from '../contexts/LocaleContext';
import { useTranslation } from 'react-i18next';

import '../css/login.css';
import axios from 'axios';

export default function Login() {
    const [logo, setLogo] = useState('');
    const [logoLoading, setLogoLoading] = useState(true);
    const { locale, setLocale } = useContext(LocaleContext);
    const { showHideToast } = useContext(ToastContext);
    const [t] = useTranslation("global");

    const { setAuth } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const userRef = useRef();


    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');

    // logo
    useEffect(() => {

        axios.get('https://panorama.site-pocket.com/api/settings/logo')
            .then(response => {
                setLogo(response.data.value);

            })
            .catch(function (error) {
                let errorMessage = 'An error occurred. Please try again.';
                if (error.response) {
                    if (error.response.status === 404) {
                        errorMessage = 'Resource not found.';
                    } else if (error.response.status === 500) {
                        errorMessage = 'Internal server error. Please try again later.';
                    } else {
                        errorMessage = 'An unexpected error occurred. Please try again later.';
                    }
                }
                showHideToast(errorMessage, "error");
            })
            .finally(() => {
                setLogoLoading(false);
            })
    }, []);
    // logo

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(
                'https://panorama.site-pocket.com/api/auth/login',
                { email: user, password: pwd },
                {
                    headers: { 'Content-Type': 'application/json' },
                }
            );
            const accessToken = response?.data?.access_token;
            const roles = response?.data?.user?.is_admin;
            setAuth({ user, pwd, roles, accessToken });
            showHideToast("login success");
            setUser('');
            setPwd('');
            navigate(from, { replace: true });
        } catch (err) {
            let errorMessage = 'An error occurred. Please try again.';
            if (err.response) {
                if (err.response.status === 400) {
                    errorMessage = 'Enter valid email. Please check your input.';
                } else if (err.response.status === 401) {
                    errorMessage = 'Unauthorized. Please log in again.';
                } else if (err.response.status === 403) {
                    errorMessage = 'Forbidden. You do not have permission to perform this action.';
                } else if (err.response.status === 404) {
                    errorMessage = 'Resource not found.';
                } else if (err.response.status === 500) {
                    errorMessage = 'Internal server error. Please try again later.';
                } else {
                    errorMessage = 'An unexpected error occurred. Please try again later.';
                }
            }
            showHideToast(errorMessage, "error");
        }
    }

    return (
        <div className="login-main">
            <div className="login">
                <div>
                    {logoLoading ? (
                        <div></div> // Render loader while logo is loading
                    ) : (
                        <>
                            <img src={logo} alt="logo" />
                            <h1>Sign In</h1>
                        </>
                    )}
                </div>
                <form onSubmit={handleSubmit}>
                    <TextField size='small' sx={{ marginBottom: 1 }} required value={user} onChange={(e) => setUser(e.target.value)} ref={userRef} id="username" autoComplete="off" label="Email" variant="outlined" />
                    <TextField size='small' sx={{ marginBottom: 1 }} required value={pwd} onChange={(e) => setPwd(e.target.value)} ref={userRef} id="password" label="Password" variant="outlined" type="password" />
                    <button>Sign In</button>
                </form>
                <div className="login-footer">Copyright © 2024 Panorama</div>
            </div >
        </div>
    )
}
