import { Box, Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Avatar from '@mui/material/Avatar';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';

import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastContext } from '../../contexts/ToastContext';
import { LocaleContext } from '../../contexts/LocaleContext';
import { useTheme } from '@mui/material/styles';

export default function RoomsTableEditCrud({ showEditDialog, setShowEditDialog, selectedImages, setSelectedImages, setImagePreviews, updatedRows, setUpdatedRows, selectedAmenities, imagePreviews, roomtype, setRoomtype, rowToEdit, setRunUseEffect, roomTypes, amenities, handleCheckboxChange, handleEditImageChange, handleEditRemoveImage }) {
    const { locale, setLocale } = useContext(LocaleContext);
    const { showHideToast } = useContext(ToastContext);
    const [t] = useTranslation("global");
    const theme = useTheme({
        Typography: {
            fontFamily: [
                'Almarai',
            ].join(','),
        }
    });

    const [loading, setLoading] = useState(false);
    const handleClose = () => {
        setLoading(false);
    };
    const handleOpen = () => {
        setLoading(true);
    };

    //  ================ edit function ================
    function handleEditClose() {
        setShowEditDialog(false);
        setSelectedImages([]);
        setImagePreviews([]);
    }

    function handleEditSubmit(event) {
        event.preventDefault();
        handleOpen();
        const url = `https://panorama.site-pocket.com/api/rooms/${rowToEdit}`;
        let formData = new FormData();
        formData.append('_method', 'PUT');
        formData.append('price', updatedRows.Price);
        formData.append('room_number', updatedRows.RoomNo);
        formData.append('status', updatedRows.Status);
        formData.append('room_type_id', updatedRows.RoomTypeId);
        selectedAmenities.forEach(amenityId => {
            formData.append('amenity_ids[]', amenityId);
        });

        // Append existing image paths
        imagePreviews.forEach(preview => {
            // Check if the preview is not a File object (i.e., it's a URL)
            if (!(preview instanceof File)) {
                formData.append(`images[]`, preview);
            }
        });

        // Append newly uploaded images
        selectedImages.forEach(image => {
            // Check if the file is a valid image
            if (image instanceof File && isValidImageFile(image)) {
                formData.append(`images[]`, image);
            }
        });

        const tokenString = localStorage.getItem('auth');
        const tokenObject = JSON.parse(tokenString);
        const token = tokenObject.accessToken;

        axios.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`,
            }
        }).then(function (response) {
            const updatedRowsArray = roomtype.map((row) => {
                if (row.id === rowToEdit) {
                    return {
                        ...row,
                        price: updatedRows.Price,
                        room_number: updatedRows.RoomNo,
                        status: updatedRows.Status,
                        room_type_id: updatedRows.RoomTypeId,
                        amenities: response.data.room.amenities,
                        image_path: response.data.room.image_path.split(','),
                    };
                }
                return row;
            });
            setRoomtype(updatedRowsArray);
            setShowEditDialog(false);
            showHideToast(t("edited successfully"));
            setRunUseEffect((prev) => prev + 1);
            handleClose();
        })
            .catch(function (error) {
                let errorMessage = 'An error occurred. Please try again.';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = 'Bad request. Please check your input.';
                    } else if (error.response.status === 401) {
                        errorMessage = 'Unauthorized. Please log in again.';
                    } else if (error.response.status === 403) {
                        errorMessage = 'Forbidden. You do not have permission to perform this action.';
                    } else if (error.response.status === 404) {
                        errorMessage = 'Resource not found.';
                    } else if (error.response.status === 500) {
                        errorMessage = 'Internal server error. Please try again later.';
                    } else if (error.response.data.message.includes('The price field must be a number')) {
                        errorMessage = 'The price field must be a number.';
                    } else if (error.response.data.message.includes('The room number field must be a string')) {
                        errorMessage = 'The room number field must be a string.';
                    } else {
                        errorMessage = 'An unexpected error occurred. Please try again later.';
                    }
                }
                handleClose();
                showHideToast(errorMessage, "error");
            });
    }

    function isValidImageFile(file) {
        // List of allowed image file extensions
        const allowedExtensions = ['jpeg', 'jpg', 'png', 'gif'];
        // Get the file extension
        const extension = file.name.split('.').pop().toLowerCase();
        // Check if the extension is included in the allowed list
        return allowedExtensions.includes(extension);
    }
    //  ================ edit function ================

    return (
        <div>
            {loading && <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
            >
                <CircularProgress color="inherit" />
            </Backdrop>}
            <Dialog
                dir={locale === "en" ? "ltr" : "rtl"}
                className={theme.palette.mode === 'light' ? 'light' : 'dark'}
                open={showEditDialog}
                onClose={handleEditClose}
            >
                <DialogTitle sx={{ pb: 1 }}>{t('Edit')}</DialogTitle>
                <DialogContent sx={{ pt: '10px !important', pb: '0px !important' }}>
                    <TextField value={updatedRows.Price} onChange={(e) => setUpdatedRows({ ...updatedRows, Price: e.target.value })} sx={{ mb: 2 }} fullWidth label={t("Price")} type="text" size="small" />
                    <TextField value={updatedRows.RoomNo} onChange={(e) => setUpdatedRows({ ...updatedRows, RoomNo: e.target.value })} sx={{ mb: 2 }} fullWidth label={t("Room No.")} type="text" size="small" />
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel>{t('Status')}</InputLabel>
                        <Select
                            value={updatedRows.Status}
                            onChange={(e) => {
                                setUpdatedRows({ ...updatedRows, Status: e.target.value })
                            }}
                            name="Status"
                            required
                        >
                            <MenuItem value="available">available</MenuItem>
                            <MenuItem value="unavailable">unavailable</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel>{t('Room Type')}</InputLabel>
                        <Select
                            value={updatedRows.RoomTypeId}
                            onChange={(e) => setUpdatedRows({ ...updatedRows, RoomTypeId: e.target.value })}
                            name="RoomTypeId"
                            required
                        >
                            {roomTypes.map(roomType => (
                                <MenuItem key={roomType.id} value={roomType.id}>{locale === "en" ? roomType.name_en : roomType.name_ar}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <div className="group">
                        <h2 style={{ margin: "0 0 10px 0", fontWeight: 'normal', fontSize: '18px' }}>{t('Amenities')}</h2>
                        <div className="checkbox-group">
                            {amenities.map(amenity => (
                                <div className='list' key={amenity.id}>
                                    <input
                                        name='amenities'
                                        type="checkbox"
                                        id={`amenity-${amenity.id}`}
                                        onChange={(e) => handleCheckboxChange(e, amenity.id)}
                                        className="checkbox"
                                        checked={selectedAmenities.includes(amenity.id)}
                                    />
                                    <label htmlFor={`amenity-${amenity.id}`} className="item">
                                        <img style={{ width: '40px', maxHeight: '100px' }} src={amenity.image_path} alt={amenity.name_en} className='logo-type' />
                                        <div style={{ fontSize: '14px' }}>{locale === "en" ? amenity.name_en : amenity.name_ar}</div>
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>

                    <input
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        multiple
                        onChange={handleEditImageChange}
                    />
                    <Button className='roomsbtn' variant="outlined" component="label" sx={{ textTransform: 'capitalize' }}>
                        {t('Choose Image')}
                        <input multiple type="file" accept="image/*" style={{ display: 'none' }} onChange={handleEditImageChange} />
                    </Button>
                    {/* Display image previews */}
                    <Box display="flex" flexDirection="row" flexWrap="wrap">
                        {imagePreviews.map((preview, index) => (
                            <Box key={`preview-${index}`} position="relative" m={1}>
                                <Avatar
                                    alt={`Image ${index + 1}`}
                                    src={preview}
                                    sx={{ width: 100, height: 100 }}
                                />
                                <IconButton
                                    onClick={() => handleEditRemoveImage(index)}
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        right: 0,
                                        color: 'white',
                                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                        ))}
                    </Box>

                </DialogContent>
                <DialogActions>
                    <Button sx={{ textTransform: 'capitalize' }} variant="contained" color="error" onClick={handleEditClose}>{t('Cancel')}</Button>
                    <Button className='dialogDeleteBtn' onClick={handleEditSubmit} sx={{ textTransform: 'capitalize' }} variant="contained" type="submit">{t('Confirm')}</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
