import { Container } from '@mui/material';
import BookingTable from '../component/BookingTable';

export default function Booking() {
    return (
        <div>
            <Container maxWidth="xl">
                <BookingTable />
            </Container>
        </div>
    )
}
